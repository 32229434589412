import React, { ReactNode, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import userPool from '../userPool';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router';
import moment from 'moment';

interface AuthContextProps {
  accessToken: string;
  setNewTokens: (access: string, refresh: string, id: string) => void;
  fetchTokensWithAuthorizationCode: (code: string) => Promise<void>;
  usernamePasswordSignIn: (username: string, password: string) => void;
  googleSignIn: () => void;
  getAccessToken: () => Promise<string>;
  logout: () => void;
  isLoggedIn: () => boolean;
  userID: string;
  idToken: string;
}

export const AuthContext = React.createContext<AuthContextProps>({
  accessToken: '',
  setNewTokens: (access: string, refresh: string, id: string) => {},
  fetchTokensWithAuthorizationCode: (code: string) => new Promise((r) => r()),
  usernamePasswordSignIn: (username: string, password: string) => {},
  googleSignIn: () => {},
  getAccessToken: async () => '',
  logout: () => {},
  isLoggedIn: () => false,
  userID: '',
  idToken: '',
});

interface AuthProviderProps {
  children: ReactNode;
}

// Hosted UI
// 'https://perseedu.auth.eu-central-1.amazoncognito.com/oauth2/authorize?redirect_uri=http://localhost:3000/callback&response_type=CODE&client_id=1951tm9h8f5go52ca4b1kcs8rj&scope=aws.cognito.signin.user.admin email openid phone profile';

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [accessTokenExpirationTimestamp, setAccessTokenExpirationTimestamp] =
    useState(0);
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [idToken, setIdToken] = useState('');
  const [userSub, setUserSub] = useState('');

  useEffect(() => {
    let refresh_token = sessionStorage.getItem('refresh_token');
    if (!refresh_token) return;
    setRefreshToken(refresh_token);
  }, []);

  const setNewTokens = (access: string, refresh: string, id: string) => {
    setAccessToken(access);
    setRefreshToken(refresh);
    setIdToken(id);

    sessionStorage.setItem('refresh_token', refresh);

    var decodedAccessToken: any = jwt_decode(access);

    setAccessTokenExpirationTimestamp(parseInt(decodedAccessToken.exp));
    setUserSub(decodedAccessToken.sub);
  };

  const getAccessToken = () => {
    return new Promise<string>(async (resolve, reject) => {
      const ERROR_REFRESH_TOKEN_NOT_PRESENT =
        'Refresh token is not present in sessionStorage.';
      try {
        if (
          accessTokenExpirationTimestamp > moment().unix() &&
          accessToken !== ''
        ) {
          resolve(accessToken);
        } else {
          var refToken = refreshToken;
          if (refToken === '') {
            let refresh_token = sessionStorage.getItem('refresh_token');
            if (!refresh_token)
              throw new Error(ERROR_REFRESH_TOKEN_NOT_PRESENT);
            setRefreshToken(refresh_token);
            refToken = refresh_token;
          }
          const res = await axios.post(
            `https://perseedu.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=refresh_token&refresh_token=${refToken}&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}`,
            {},
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );
          const { access_token, id_token } = res.data;
          setNewTokens(access_token, refToken, id_token);
          resolve(access_token);
        }
      } catch (error: any) {
        const { message } = error;
        if (message === ERROR_REFRESH_TOKEN_NOT_PRESENT) {
          // console.log('auth provider navigate to login');
          // navigate('/login');
        }
        reject('AccessToken fetching failed.');
      }
    });
  };

  useEffect(() => {
    const fetchAccessTokenAndSetSub = async () => {
      const res = await axios.post(
        `https://perseedu.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}`,
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      const { access_token } = res.data;
      var decodedAccessToken: any = jwt_decode(access_token);
      const { sub } = decodedAccessToken;
      if (sub) {
        setUserSub(sub);
      }
    };
    if (!userSub && refreshToken) {
      fetchAccessTokenAndSetSub();
    }
  }, [refreshToken, userSub]);

  const usernamePasswordSignIn = (username: string, password: string) => {
    var userData = {
      Username: username,
      Pool: userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    var authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        const refresh = result.getRefreshToken().getToken();
        const access = result.getAccessToken().getJwtToken();
        const id = result.getIdToken().getJwtToken();
        setNewTokens(access, refresh, id);
        navigate('/');
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  const googleSignIn = () => {
    const url = `https://perseedu.auth.eu-central-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${window.location.origin}/callback&response_type=code&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&scope=aws.cognito.signin.user.admin email openid phone profile`;
    window.open(url, '_self');
  };

  const fetchTokensWithAuthorizationCode = async (code: string) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const res = await axios.post(
          `https://perseedu.auth.eu-central-1.amazoncognito.com/oauth2/token?grant_type=authorization_code&redirect_uri=${window.location.origin}/callback&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}&code=${code}`,
          {},
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        const { access_token, refresh_token, id_token } = res.data;
        setNewTokens(access_token, refresh_token, id_token);
        resolve();
      } catch (error) {
        console.log(error);
        reject();
      }
    });
  };

  const logout = () => {
    const logoutURL = `https://perseedu.auth.eu-central-1.amazoncognito.com/logout?logout_uri=${window.location.origin}/logout&client_id=${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}`;
    window.open(logoutURL, '_self');
    sessionStorage.removeItem('refresh_token');
    setAccessToken('');
    setRefreshToken('');
    setIdToken('');
    setUserSub('');
    sessionStorage.clear();
  };

  const isLoggedIn = () => {
    return refreshToken !== '';
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken: accessToken,
        setNewTokens: setNewTokens,
        fetchTokensWithAuthorizationCode: fetchTokensWithAuthorizationCode,
        usernamePasswordSignIn: usernamePasswordSignIn,
        googleSignIn: googleSignIn,
        getAccessToken: getAccessToken,
        logout: logout,
        isLoggedIn: isLoggedIn,
        userID: userSub,
        idToken: idToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
