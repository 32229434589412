import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  return (
    <div>
      <p className='title'>Logout successful</p>
      <button className='orange-button' onClick={() => navigate('/login')}>
        Got to Login
      </button>
    </div>
  );
};

export default Logout;
