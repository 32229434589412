import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createURL, fetcherAuth } from '../helper';
import { AuthContext } from '../provider/AuthProvider';
import useSWR from 'swr';
import { IUser } from '../models/user';
import PrimaryButton from './buttons/PrimaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faStar } from '@fortawesome/free-solid-svg-icons';

const UserOnboarding = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useContext(AuthContext);

  const [navigationIndex, setNavigationIndex] = useState(0);
  const [currentOccupation, setCurrentOccupation] = useState('');
  const [countryOfResidence, setCountryOfResidence] = useState('');
  const [impactPlan, setImpactPlan] = useState('');
  const [reasonForRaising, setReasonForRaising] = useState('');
  const [expensesResponse, setExpensesResponse] = useState('');
  const [socialMediaPresence, setSocialMediaPresence] = useState('');
  const [uniqueFact, setUniqueFact] = useState('');

  const passionTopics = [
    'innovation',
    'technology',
    'business',
    'entrepreneurship',
    'design',
    'art',
    'reading',
    'music',
    'science',
    'traveling',
    'sports',
    'philosophy',
  ];

  const titles = [
    'student',
    'entrepreneur',
    'activist',
    'impact creator',
    'changemaker',
    'defi enthusiast',
  ];

  const userURL: string = createURL(`users/`);
  const { data: user, error: userError } = useSWR<IUser>(userURL, async (url) =>
    fetcherAuth(url, await getAccessToken())
  );

  const handleNavigation = (direction: string) => {
    if (direction === 'forward') {
      setNavigationIndex((state) => state + 1);
    } else {
      setNavigationIndex((state) => state - 1);
    }
  };

  const renderPassionTopic = (topic: string) => {
    return (
      <p
        className='border-black-500 mx-4 cursor-pointer rounded-md border-2 py-3 text-center font-bold md:w-48 '
        key={topic}
      >
        {topic}
      </p>
    );
  };

  const renderDescriptionTitle = (title: string) => {
    return (
      <p
        className='border-black-500 mx-4 cursor-pointer rounded-md border-2 py-3 text-center font-bold md:w-48'
        key={title}
      >
        {title}
      </p>
    );
  };

  if (userError) console.log('Failed to load user.');

  return (
    <div className='h-screen w-full bg-primary-700 p-2 pt-12'>
      <div className='absolute top-0 p-2'>
        <img className='w-32' src='../assets/images/perseedu-logo.png' alt='' />
      </div>
      <div className='flex h-full flex-col items-center overflow-y-scroll rounded bg-white pb-5'>
        {navigationIndex === 0 && (
          <>
            <div className='flex justify-center py-10'>
              <p className='text-xl font-bold '>
                Which role fits your profile ?
              </p>
            </div>
            <div className='flex flex-col gap-5 md:flex-row '>
              <div className='flex w-full cursor-pointer flex-col items-center rounded-md bg-white p-2 drop-shadow-md  '>
                <p className='pb-2 text-xl font-bold'>Talent</p>
                <p>I would like to create my own social token.</p>
                <FontAwesomeIcon className='m-5 text-2xl' icon={faStar} />
                <PrimaryButton
                  className='mb-2'
                  title='Select'
                  onClick={() => {
                    // handleNavigation('forward')
                    navigate('/');
                  }}
                />
              </div>
              <div className='min-w-smd flex w-full cursor-pointer flex-col items-center rounded-md bg-white p-2 drop-shadow-lg '>
                <p className='pb-2 text-xl font-bold'>Sponsor</p>
                <p>I would like to sponsor a talent.</p>
                <FontAwesomeIcon
                  className='m-5 text-2xl'
                  icon={faBalanceScale}
                />
                <PrimaryButton
                  className='mb-2'
                  title='Select'
                  onClick={() => {
                    // handleNavigation('forward')
                    navigate('/');
                  }}
                />
              </div>
            </div>

            <div className='w-sm mt-5 flex cursor-pointer flex-col items-center rounded-md bg-white p-2  drop-shadow-lg'>
              <p
                onClick={() => {
                  // handleNavigation('forward')
                  navigate('/');
                }}
                className='p-2'
              >
                I just want to have a look on the platform.
              </p>
            </div>
          </>
        )}

        {navigationIndex === 1 && (
          <>
            <div className='flex justify-center py-10'>
              <p className='text-xl font-bold '>
                What are you passionate about?
              </p>
            </div>
            <div className='flex w-full flex-col gap-3 md:flex-row md:flex-wrap md:justify-center md:px-48'>
              {passionTopics.map((topic) => renderPassionTopic(topic))}
            </div>

            <div className='mt-4 flex w-full justify-center'>
              <PrimaryButton
                className='mr-3 bg-gray-400'
                title='Back'
                onClick={() => handleNavigation('backward')}
              />
              <PrimaryButton
                title='Next'
                onClick={() => handleNavigation('forward')}
              />
            </div>
          </>
        )}

        {navigationIndex === 2 && (
          <>
            <div className='flex justify-center py-10 px-5'>
              <p className='text-xl font-bold '>
                Which words describe your talent best?
              </p>
            </div>
            <div className='flex w-full flex-col gap-3 md:flex-row md:flex-wrap md:justify-center md:px-48'>
              {titles.map((title) => renderDescriptionTitle(title))}
            </div>

            <div className='mt-4 flex w-full justify-center'>
              <PrimaryButton
                className='mr-3 bg-gray-400'
                title='Back'
                onClick={() => handleNavigation('backward')}
              />
              <PrimaryButton
                title='Next'
                onClick={() => handleNavigation('forward')}
              />
            </div>
          </>
        )}

        {navigationIndex === 3 && (
          <>
            <div className='flex justify-center py-10 px-5'>
              <p className='text-xl font-bold '>
                What are you currently doing?
              </p>
            </div>
            <div className='w-full max-w-screen-md rounded-lg p-8'>
              <form
                className='flex flex-col'
                onSubmit={(event) => event.preventDefault()}
              >
                <label className='text-md pb-1'>Current occupation</label>
                <textarea
                  className='mr-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1'
                  rows={5}
                  value={currentOccupation}
                  placeholder='e.g. currently a student, working on a business idea, building a community'
                  onChange={(event) => setCurrentOccupation(event.target.value)}
                />
              </form>
            </div>

            <div className='mt-4 flex w-full justify-center'>
              <PrimaryButton
                className='mr-3 bg-gray-400'
                title='Back'
                onClick={() => handleNavigation('backward')}
              />
              <PrimaryButton
                title='Next'
                onClick={() => handleNavigation('forward')}
              />
            </div>
          </>
        )}

        {navigationIndex === 4 && (
          <>
            <div className='flex justify-center py-10 px-5'>
              <p className='text-xl font-bold '>
                In which country do you currently live in?
              </p>
            </div>
            <div className='w-full max-w-screen-md rounded-lg p-8'>
              <form
                className='flex flex-col'
                onSubmit={(event) => event.preventDefault()}
              >
                <label className='text-md pb-1'>Country</label>
                <textarea
                  className='mr-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1'
                  rows={5}
                  value={countryOfResidence}
                  placeholder='e.g. Switzerland'
                  onChange={(event) =>
                    setCountryOfResidence(event.target.value)
                  }
                />
              </form>
            </div>

            <div className='mt-4 flex w-full justify-center'>
              <PrimaryButton
                className='mr-3 bg-gray-400'
                title='Back'
                onClick={() => handleNavigation('backward')}
              />
              <PrimaryButton
                title='Next'
                onClick={() => handleNavigation('forward')}
              />
            </div>
          </>
        )}

        {navigationIndex === 5 && (
          <>
            <div className='flex justify-center py-10 px-5'>
              <p className='text-xl font-bold '>
                How do you want to impact the future?
              </p>
            </div>
            <div className='w-full max-w-screen-md rounded-lg p-8'>
              <form
                className='flex flex-col'
                onSubmit={(event) => event.preventDefault()}
              >
                <label className='text-md pb-1'>My impact plan</label>
                <textarea
                  className='mr-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1'
                  rows={5}
                  value={impactPlan}
                  placeholder='e.g. my business idea will create a social impact, with my studies I will bring research in a certain field forward...'
                  onChange={(event) => setImpactPlan(event.target.value)}
                />
              </form>
            </div>

            <div className='mt-4 flex w-full justify-center'>
              <PrimaryButton
                className='mr-3 bg-gray-400'
                title='Back'
                onClick={() => handleNavigation('backward')}
              />
              <PrimaryButton
                title='Next'
                onClick={() => handleNavigation('forward')}
              />
            </div>
          </>
        )}

        {navigationIndex === 6 && (
          <>
            <div className='flex justify-center py-10 px-5'>
              <p className='text-xl font-bold '>Why are you raising funds?</p>
            </div>
            <div className='w-full max-w-screen-md rounded-lg p-8'>
              <form
                className='flex flex-col'
                onSubmit={(event) => event.preventDefault()}
              >
                <label className='text-md pb-1'>Reason</label>
                <textarea
                  className='mr-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1'
                  rows={5}
                  value={reasonForRaising}
                  placeholder='e.g. I need funds to validate my business idea on the market'
                  onChange={(event) => setReasonForRaising(event.target.value)}
                />
              </form>
            </div>

            <div className='mt-4 flex w-full justify-center'>
              <PrimaryButton
                className='mr-3 bg-gray-400'
                title='Back'
                onClick={() => handleNavigation('backward')}
              />
              <PrimaryButton
                title='Next'
                onClick={() => handleNavigation('forward')}
              />
            </div>
          </>
        )}

        {navigationIndex === 7 && (
          <>
            <div className='flex justify-center py-10 px-5'>
              <p className='text-xl font-bold '>
                What expenses will you cover with the donations?
              </p>
            </div>
            <div className='w-full max-w-screen-md rounded-lg p-8'>
              <form
                className='flex flex-col'
                onSubmit={(event) => event.preventDefault()}
              >
                <label className='text-md pb-1'>Expenses</label>
                <textarea
                  className='mr-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1'
                  rows={5}
                  value={expensesResponse}
                  placeholder='e.g. the raised donations will be primarily spent on developers to create an MVP'
                  onChange={(event) => setExpensesResponse(event.target.value)}
                />
              </form>
            </div>

            <div className='mt-4 flex w-full justify-center'>
              <PrimaryButton
                className='mr-3 bg-gray-400'
                title='Back'
                onClick={() => handleNavigation('backward')}
              />
              <PrimaryButton
                title='Next'
                onClick={() => handleNavigation('forward')}
              />
            </div>
          </>
        )}

        {navigationIndex === 8 && (
          <>
            <div className='flex justify-center py-10 px-5'>
              <p className='text-xl font-bold '>
                Do you already have a community or social media presence?
              </p>
            </div>
            <div className='w-full max-w-screen-md rounded-lg p-8'>
              <form
                className='flex flex-col'
                onSubmit={(event) => event.preventDefault()}
              >
                <label className='text-md pb-1'>
                  Leave us your social media tags
                </label>
                <textarea
                  className='mr-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1'
                  rows={5}
                  value={socialMediaPresence}
                  placeholder='e.g. links to your instagram,facebook, linkedin, discord, tiktok '
                  onChange={(event) =>
                    setSocialMediaPresence(event.target.value)
                  }
                />
              </form>
            </div>

            <div className='mt-4 flex w-full justify-center'>
              <PrimaryButton
                className='mr-3 bg-gray-400'
                title='Back'
                onClick={() => handleNavigation('backward')}
              />
              <PrimaryButton
                title='Next'
                onClick={() => handleNavigation('forward')}
              />
            </div>
          </>
        )}

        {navigationIndex === 9 && (
          <>
            <div className='flex justify-center py-10 px-5'>
              <p className='text-xl font-bold '>
                Tell us something unique about you?
              </p>
            </div>
            <div className='w-full max-w-screen-md rounded-lg p-8'>
              <form
                className='flex flex-col'
                onSubmit={(event) => event.preventDefault()}
              >
                <label className='text-md pb-1'>Fun fact</label>
                <textarea
                  className='mr-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1'
                  rows={5}
                  value={uniqueFact}
                  placeholder='e.g. I speak 4 languages'
                  onChange={(event) => setUniqueFact(event.target.value)}
                />
              </form>
            </div>

            <div className='mt-4 flex w-full justify-center'>
              <PrimaryButton
                title='Next'
                onClick={() => handleNavigation('forward')}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserOnboarding;
