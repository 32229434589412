import { randomProfileImageURL } from '../helper';

const TalentProfile = () => {
  return (
    <div className='talent-profile-section'>
      <div className='hero-video'>
        <img
          src='https://images.unsplash.com/photo-1586227740560-8cf2732c1531?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1861&q=80'
          alt=''
        />
      </div>

      <div className='profile-info-top-container'>
        <div className='profile-image'>
          <img src={randomProfileImageURL()} alt='' />
        </div>
        <div className='info'>
          <p className='title'>Bethany Haley ($BTH)</p>
          <p className='title'>University of St.Gallen</p>
          <p className='tags'>STUDENT</p>
          <div className='stats'>
            <p>35 Updates</p>
            <p>84 Followers</p>
            <p>30 Backers</p>
          </div>
        </div>
      </div>

      <div className='talent-bio-container'>
        <p className='title'>About</p>
        <p>
          Hey, I’m Bethany Haley, 23 Years old student, currently doing my
          Master in Finance at University of St. Gallen. CEO of “eaty” a vegan
          burger fast food located in Zurich. Co-Founder of B&H, a startup that
          provides consulting services.
        </p>
        <p className='title'>Why am I raising funds</p>
        <p>
          I’m raising funds so that I can continue my career as a student at HSG
          where I would like to work side by side with the University. Other
          than that: I want to improve the world. Right now, I'm working on a
          project for the developing world. It aims to provide low-cost access
          to clean water through local production and distribution of water
          filters. Keeping you soon up-to-date!
        </p>
        <p className='title'>Experiences</p>
        <p>
          Internship at Nestlé in 2019 Passed Assessment year at the University
          of St.Gallen Wrote bachelor thesis about ESG investments Sport
          instructor for sailors
        </p>
      </div>

      <div className='token-info-container'>
        <p className='title'>$BTH token</p>
        <p>
          $BTH tokens can be used for various uses. In the short-term, you are
          obviously creating a real direct impact on that student, in the
          long-term, maybe, meaningful relationships will be created.
        </p>
        <img src='../assets/images/tokenChartMock.png' alt='' />
      </div>
    </div>
  );
};

export default TalentProfile;
