import React from 'react';
import { showToast } from '../helper';

interface Props {
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  multiple?: boolean;
  limitInMB?: number;
}

const FileSelector: React.FunctionComponent<Props> = ({
  setSelectedFiles,
  multiple = true,
  limitInMB = 8,
}) => {
  const handleFileSelect = (event: any) => {
    const { files } = event.target;
    const MAX_FILE_SIZE = limitInMB * 1024 * 1024;
    const listOfFiles: File[] = [];
    for (const file of files) {
      if (file.size > MAX_FILE_SIZE) {
        showToast('File is too large. (Max. 10MB)', 'error');
        return;
      }
      listOfFiles.push(file);
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...listOfFiles]);
  };

  return (
    <label className='ml-3 w-64 flex flex-col items-center px-4 py-6 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-purple-600 hover:text-white text-purple-600 ease-linear transition-all duration-150'>
      <p>ICON</p>
      <span className='mt-2 text-base leading-normal'>Select a file</span>
      <input
        type='file'
        className='hidden'
        onChange={handleFileSelect}
        multiple={multiple}
      />
    </label>
  );
};

export default FileSelector;
