import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAdjust,
  faBars,
  faCompass,
  faHouse,
  faPray,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnyNsRecord } from 'dns';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthContext } from '../provider/AuthProvider';

const Sidebar = () => {
  const navigate = useNavigate();
  const { userID, logout } = useContext(AuthContext);

  const [showSidebarMobile, setShowSidebarMobile] = useState(false);

  const Link: FC<{ title: string; path: string; icon: any }> = ({
    title,
    path,
    icon,
  }) => {
    return (
      <div className='flex items-center pl-6 hover:bg-gray-500'>
        <FontAwesomeIcon icon={icon} style={{ marginRight: '6px' }} />
        <p
          className='flex cursor-pointer py-3 font-bold'
          onClick={() => {
            setShowSidebarMobile(false);
            navigate(path);
          }}
        >
          {title}
        </p>
      </div>
    );
  };

  const renderSidebarContent = () => {
    return (
      <div className='absolute top-10 flex h-screen w-full flex-col bg-white'>
        {/* <div className='hidden md:flex flex-col w-full bg-white h-screen'> */}
        <div className='flex h-16 items-center justify-center bg-blue-800'>
          <img
            className='w-32'
            src='../assets/images/perseedu-logo.png'
            alt='perseedu-logo'
          />
        </div>

        <div className='flex h-20 items-center justify-start bg-blue-200'>
          <div className='flex items-center justify-center pl-2'>
            <img
              className='ml-1 mr-3 h-14 w-14 rounded-full object-cover'
              src='../assets/images/profile.png'
              alt='user-profile-image'
            />
          </div>
          <div>
            <p className='text-sm font-bold'>John Smith</p>
            <p className='text-xs'>mail@mail.mail</p>
          </div>
        </div>

        <Link title='Dashboard' path='/' icon={faAdjust} />
        <Link title='Your Profile' path={`/profiles/${userID}`} icon={faPray} />

        <button className='orange-button' onClick={() => logout()}>
          Logout
        </button>
      </div>
    );
  };

  return (
    <>
      <div
        className='flex w-full items-center justify-between px-2 md:hidden '
        // onClick={() => setShowSidebarMobile((state) => !state)}
      >
        <p>perseedU</p>
        {/* <FontAwesomeIcon icon={faBars} /> */}
      </div>

      {showSidebarMobile && renderSidebarContent()}

      <div className='hidden h-screen w-full flex-col bg-white md:flex'>
        <div className='flex h-16 items-center justify-center bg-primary-700'>
          <img
            className='w-32'
            src='../assets/images/perseedu-logo.png'
            alt='perseedu-logo'
          />
        </div>

        {/* <div className='flex h-20 items-center justify-start bg-blue-200'>
          <div className='flex items-center justify-center pl-2'>
            <img
              className='ml-1 mr-3 h-14 w-14 rounded-full object-cover'
              src='../assets/images/profile.png'
              alt='user-profile-image'
            />
          </div>
          <div>
            <p className='text-sm font-bold'>John Smith</p>
            <p className='text-xs'>mail@mail.mail</p>
          </div>
        </div> */}

        <Link title='Dashboard' path='/' icon={faHouse} />
        <Link title='Your Profile' path={`/profiles/${userID}`} icon={faUser} />
        <Link title='Explore' path={`/explore`} icon={faCompass} />

        <button className='orange-button' onClick={() => logout()}>
          Logout
        </button>
      </div>
    </>
  );
};
export default Sidebar;
