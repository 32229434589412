import { useState } from 'react';

const TokenCalculator = () => {
  const [priceSeedInUSD, setPriceSeedInUSD] = useState('1.0');
  const [tokenSupply, setTokenSupply] = useState('10');
  const [buyAmount, setBuyAmount] = useState('1');
  const [buyAmountUSD, setBuyAmountUSD] = useState('15');
  const [sellAmount, setSellAmount] = useState('1');

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'priceSeedInUSD') {
      setPriceSeedInUSD(value);
    }
    if (name === 'tokenSupply') {
      setTokenSupply(value);
    }
    if (name === 'buyAmount') {
      setBuyAmount(value);
    }
    if (name === 'buyAmountUSD') {
      setBuyAmountUSD(value);
    }
    if (name === 'sellAmount') {
      setSellAmount(value);
    }
  };

  const calculateTotalValueLocked = () => {
    // Integrate from 0 to supply of our function
    return (
      0.5 *
      0.003 *
      parseFloat(tokenSupply) *
      parseFloat(tokenSupply) *
      parseFloat(priceSeedInUSD)
    );
  };

  const calculatePriceToBuy = () => {
    const amount = parseFloat(buyAmount);
    const currentSupply = parseFloat(tokenSupply);
    const newSupply = currentSupply + amount;
    return (
      0.5 * 0.003 * (newSupply * newSupply - currentSupply * currentSupply)
    );
  };

  const calculateTokenAmountForUSD = () => {
    const amount = parseFloat(buyAmountUSD);
    const currentSupply = parseFloat(tokenSupply);

    const a = currentSupply * currentSupply * currentSupply;
    // const result = -a + amount / (0.003 * 0.5);
    const result = -a + (amount * 1000) / 6;
    return Math.pow(result, 1 / 3) - currentSupply;
  };

  const calculatePriceToSell = () => {
    const amount = parseFloat(sellAmount);
    const currentSupply = parseFloat(tokenSupply);
    const newSupply = currentSupply - amount;
    return (
      0.46 *
      0.5 *
      0.003 *
      (currentSupply * currentSupply - newSupply * newSupply)
    );
  };

  return (
    <div className='p-3'>
      <p className='font-bold'>Talent Token Calculator</p>

      <form
        className='p-5 bg-red-500'
        onSubmit={(event) => event.preventDefault()}
      >
        <label>Price SEED token in USD</label>
        <input
          className='p-1 m-1 w-full font-bold'
          name='priceSeedInUSD'
          value={priceSeedInUSD}
          onChange={handleOnChange}
        ></input>
        <label>Current token supply</label>
        <input
          className='p-1 m-1 w-full font-bold'
          name='tokenSupply'
          value={tokenSupply}
          onChange={handleOnChange}
        ></input>
      </form>

      <div className='py-3'>
        <p className='font-bold'>Price SEED token in USD</p>
        <p>Total value locked: {calculateTotalValueLocked()} USD </p>
      </div>

      <div className='py-3'>
        <p className='font-bold'>Bonding curve</p>
        <p>price_talent_token_in_SEED = 0.003 * token_supply ^2 </p>
      </div>

      <div className='py-3 mt-5'>
        <p className='font-bold'>
          Buy calculations (Specify amount of tokens you want to buy)
        </p>
        <p>
          Price for {parseFloat(buyAmount)} tokens ={' '}
          <span className='font-bold'>
            {calculatePriceToBuy()} SEED ={' '}
            {calculatePriceToBuy() * parseFloat(priceSeedInUSD)} USD
          </span>
        </p>
        <p>3% PerseedU = {calculatePriceToBuy() * 0.03} SEED</p>
        <p>1% Staking = {calculatePriceToBuy() * 0.01} SEED</p>
        <p>50% Talent = {calculatePriceToBuy() * 0.5} SEED</p>
        <p>46% Locked in smartcontract = {calculatePriceToBuy() * 0.46} SEED</p>
      </div>
      <form
        className='p-5 bg-red-500'
        onSubmit={(event) => event.preventDefault()}
      >
        <label>Buy number of tokens:</label>
        <input
          className='p-1 m-1 w-full font-bold'
          name='buyAmount'
          value={buyAmount}
          onChange={handleOnChange}
        ></input>
      </form>

      <div className='py-3 mt-5'>
        <p className='font-bold'>
          Buy calculations (Specify amount of USD you want to invest)
        </p>
        <p>
          For {parseFloat(buyAmountUSD)} USD ={' '}
          <span className='font-bold'>
            {calculateTokenAmountForUSD()} Talent tokens
          </span>
        </p>
      </div>
      <form
        className='p-5 bg-red-500'
        onSubmit={(event) => event.preventDefault()}
      >
        <label>Amount of USD to invest:</label>
        <input
          className='p-1 m-1 w-full font-bold'
          name='buyAmountUSD'
          value={buyAmountUSD}
          onChange={handleOnChange}
        ></input>
      </form>

      <div className='py-3 mt-5'>
        <p className='font-bold'>Sell calculations</p>
        <p>
          Price for {parseFloat(sellAmount)} tokens ={' '}
          <span className='font-bold'>
            {calculatePriceToSell()} SEED ={' '}
            {calculatePriceToSell() * parseFloat(priceSeedInUSD)} USD{' '}
          </span>
        </p>
      </div>
      <form
        className='p-5 bg-red-500'
        onSubmit={(event) => event.preventDefault()}
      >
        <label>Sell number of tokens:</label>
        <input
          name='sellAmount'
          className='p-1 m-1 w-full font-bold'
          value={sellAmount}
          onChange={handleOnChange}
        ></input>
      </form>
    </div>
  );
};
export default TokenCalculator;
