import React from 'react';
import { BeatLoader } from 'react-spinners';

const LoadingPage: React.FunctionComponent<{}> = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <BeatLoader />
    </div>
  );
};

export default LoadingPage;
