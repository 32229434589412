import { useNavigate } from 'react-router';
import { randomProfileImageURL } from '../helper';

const Talents = () => {
  const navigate = useNavigate();
  const suggestedTalents = [
    'Stefan',
    'Rosemary',
    'Florine',
    'Janis E.',
    'Mike D.',
  ];

  const searchResultTalents = [
    {
      name: 'Bethany Haley',
      profileImageURL: randomProfileImageURL(),
      symbol: '$BTH',
      description:
        'Hey, I’m Bethany Haley, 23 Years old student, currently doing my Master in Finance at University of St. Gallen. CEO of a vegan burger fast food located in Zurich...',
      tags: ['SOCIAL', 'ENTREPRENEUR', 'VIDEOGRAPHER'],
    },
    {
      name: 'Roberts Neumill',
      profileImageURL: randomProfileImageURL(),
      symbol: '$RBT',
      description:
        'Hey, I’m Bethany Haley, 23 Years old student, currently doing my Master in Finance at University of St. Gallen. CEO of a vegan burger fast food located in Zurich...',
      tags: ['EA', 'SOCIAL'],
    },
    {
      name: 'Angelina Ritchie',
      profileImageURL: randomProfileImageURL(),
      symbol: '$ANG',
      description:
        'Hey, I’m Bethany Haley, 23 Years old student, currently doing my Master in Finance at University of St. Gallen. CEO of a vegan burger fast food located in Zurich...',
      tags: ['SDG', 'MEDIA', 'MED'],
    },
  ];

  const supportedTalents = [
    {
      name: 'John Cummerta',
      description: 'London School of Economics',
      profileImage: randomProfileImageURL(),
    },
    {
      name: 'Miss Tricia Keebler',
      description: 'University of Bocconi',
      profileImage: randomProfileImageURL(),
    },
    {
      name: 'Kathryn Hintz',
      description: 'University of St. Gallen',
      profileImage: randomProfileImageURL(),
    },
    {
      name: 'Shelly Turcotte',
      description: 'University of Bern',
      profileImage: randomProfileImageURL(),
    },
    {
      name: 'Jim Upton',
      description: 'Colombia University',
      profileImage: randomProfileImageURL(),
    },
  ];

  return (
    <div className='talents-overview-container'>
      <div className='left-column'>
        <div className='suggested-talents-container'>
          <p className='title'>Talents that might interest you</p>
          <div className='talent-card-container'>
            {suggestedTalents.map((talent) => (
              <div
                className='talent-card'
                key={talent}
                onClick={() => navigate(`/talents/${talent}`)}
              >
                <img
                  className='profile-image'
                  src={randomProfileImageURL()}
                  alt=''
                />
                <p>{talent}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='talent-search-section'>
          <p className='title'>Search for investment</p>
          <div className='search-field-container'>
            <input className='simple-input' value='Zurich' />
            <input className='simple-input' value='Computer Science' />
            <button className='orange-button'>Search</button>
          </div>

          <div className='search-results-container'>
            <p className='title'>3 Potential Talents</p>
            {searchResultTalents.map((talent) => (
              <div
                className='talent-card'
                onClick={() => navigate(`/talents/${talent.name}`)}
              >
                <div className='profile-image-container'>
                  <img src={talent.profileImageURL} alt='' />
                </div>
                <div className='info'>
                  <p className='title'>{talent.name}</p>
                  <p>{talent.symbol}</p>
                  <p>{talent.description}</p>
                  <div className='tags'>
                    {talent.tags.map((tag) => (
                      <p className='tag'>{tag}</p>
                    ))}
                  </div>
                </div>
                <div className='stats'>
                  <p>PRICE</p>
                  <p className='title'>CHF 30</p>
                  <p>PRICE (30 DAYS)</p>
                  <p className='title'>+ 1.4%</p>
                  <p>SPONSORS</p>
                  <p className='title'>10</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='right-column'>
        <div className='summary-report-container'>
          <p className='title'>Your social impact report</p>
          <p className='title'>USD 10,467</p>
          <p>contributed</p>
          <p className='title'>High</p>
          <p>social + emotional ROI</p>
          <br />
          <p>
            You made 50 young talents' lives from 3 countries more affordable so
            far!
          </p>
        </div>

        <div className='supported-talents-container'>
          <p className='title'>Your talents</p>
          {supportedTalents.map((talent) => (
            <div
              className='talent-card'
              onClick={() => navigate(`/talents/${talent.name}`)}
            >
              <div className='profile-image'>
                <img src={talent.profileImage} alt='' />
              </div>
              <div className='info'>
                <p className='title'>{talent.name}</p>
                <p>{talent.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Talents;
