import axios, { AxiosResponse } from 'axios';
import { toast, ToastOptions } from 'react-toastify';

export const randomProfileImageURL = () => {
  const randomNumber = Math.random() * 70;
  return `https://randomuser.me/api/portraits/men/${Math.floor(
    randomNumber
  )}.jpg`;
};

export function createURL(path: String): string {
  if (path.substr(0, 1) === '/') {
    console.log('URL contains double slashes');
  }
  let BACKENDURL = process.env.REACT_APP_API_URL;
  return BACKENDURL + '/api/v1/' + path;
}

// export function getAuthUserUserID(user: User | undefined) {
//   return user?.sub?.split('|')[1] || '';
// }

export function createAuthHeader(token: string): any {
  return { headers: { Authorization: `Bearer ${token}` } };
}

export function createS3ImageURL(url: string) {
  const endpoint =
    process.env.REACT_APP_S3_BUCKET_ENDPOINT || 's3EndpointMissing';
  return `${endpoint}${url}`;
}

export const fetcherAuth = (url: string, token: string) =>
  axios(url, createAuthHeader(token))
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const fetcher = (url: string) =>
  axios(url)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });

export const showToast = (message: string, type?: string) => {
  const options: ToastOptions = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  if (type === 'success' || !type) {
    toast.success(message, options);
  }
  if (type === 'error') {
    toast.error(message, options);
  }
  if (type === 'warning') {
    toast.warn(message, options);
  }
};

export const uploadWithPresignedPostURL = (
  file: File,
  progressLogger = (event: any) => console.log(''),
  authenticated = true,
  token = 'WRONG TOKEN'
) => {
  return new Promise<{ status: number; filename: string }>(
    async (resolve, reject) => {
      try {
        if (!file) {
          showToast('Please select file.', 'error');
          // or resolve to get smooth experience
          throw new Error('File was empty.');
        }

        const backendURL: string = createURL(
          `aux/signedPostData${authenticated ? '' : 'NoAuth'}`
        );
        const { data: presignedPostData } = await axios.post(
          backendURL,
          { filename: file.name },
          createAuthHeader(token)
        );

        const filename = presignedPostData.filename;

        const formData = new FormData();
        Object.keys(presignedPostData.fields).forEach((key) => {
          formData.append(key, presignedPostData.fields[key]);
        });
        formData.append('file', file);

        const { status }: AxiosResponse = await axios.post(
          // presignedPostData.url,
          `${process.env.REACT_APP_S3_BUCKET_ENDPOINT}`,
          // 'https://sanboard-s3-main.gos3.io/',
          formData,
          {
            onUploadProgress: (progressEvent: ProgressEvent) => {
              const { loaded, total } = progressEvent;
              progressLogger(loaded / total);
            },
          }
        );

        resolve({ status, filename });
      } catch (error) {
        reject(error);
      }
    }
  );
};
