import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';
import userPool from '../userPool';
import { StringParam } from 'serialize-query-params';
// import { useQueryParam } from 'use-query-params';
import { showToast } from '../helper';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VerifyEmail = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');

  const [verificationCode, setVerificationCode] = useState('');
  const [verificationComplete, setVerificationComplete] = useState(false);

  const confirmUserRegistration = () => {
    if (verificationCode.length !== 6) {
      showToast('Code should have length of 6 digits.', 'error');
      return;
    }
    var userData = {
      Username: `${username}`,
      Pool: userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(
      verificationCode,
      true,
      function (err, result) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        console.log('verification call result: ' + result);
        setVerificationComplete(true);
      }
    );
  };

  const resendVerificationEmail = () => {
    var userData = {
      Username: `${username}`,
      Pool: userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      showToast('Verification code was sent again.', 'success');
    });
  };

  return (
    <div className='verify-email-container'>
      <div className='logo-container'>
        <img src='assets/images/perseedu-logo.png' alt='' />
      </div>
      <div className='content-container'>
        {verificationComplete ? (
          <>
            <div className='title'>
              <p className='large-title-30'>Verification successful!</p>
            </div>
            <div className='icon'>
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>

            <div className='action-container'></div>
            <button
              className='orange-button'
              onClick={() => navigate('/login')}
            >
              Login
            </button>
          </>
        ) : (
          <>
            <div className='title'>
              <p className='large-title-30'>
                Enter the verification code we sent to this mail address:
              </p>
            </div>
            <div className='mail'>
              <p className='large-title-30'>{username}</p>
            </div>
            <form
              className='short-form'
              onSubmit={(event) => event.preventDefault()}
            >
              <input
                className='bright-input'
                name='username'
                placeholder='Verification Code'
                value={verificationCode}
                onChange={(event) => setVerificationCode(event.target.value)}
              />
            </form>

            <div className='action-container'>
              <button
                className='orange-button'
                onClick={() => confirmUserRegistration()}
              >
                Verify Code
              </button>
              <button
                className='black-no-background-button'
                onClick={() => resendVerificationEmail()}
              >
                Resend verification email
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
