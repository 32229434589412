import { randomProfileImageURL } from '../helper';

const InvestorProfile = () => {
  const supportedTalents = [
    {
      name: 'Bethany',
      amount: 300,
      profileImageURL: randomProfileImageURL(),
    },
    {
      name: 'Armani',
      amount: 200,
      profileImageURL: randomProfileImageURL(),
    },
    {
      name: 'Dax',
      amount: 50,
      profileImageURL: randomProfileImageURL(),
    },
    {
      name: 'Krystina',
      amount: 2000,
      profileImageURL: randomProfileImageURL(),
    },
    {
      name: 'Gavin',
      amount: 30,
      profileImageURL: randomProfileImageURL(),
    },
    {
      name: 'Easter',
      amount: 80,
      profileImageURL: randomProfileImageURL(),
    },
    {
      name: 'Avery',
      amount: 30,
      profileImageURL: randomProfileImageURL(),
    },
    {
      name: 'Candice',
      amount: 100,
      profileImageURL: randomProfileImageURL(),
    },
    {
      name: 'Marta',
      amount: 400,
      profileImageURL: randomProfileImageURL(),
    },
    {
      name: 'Orie',
      amount: 500,
      profileImageURL: randomProfileImageURL(),
    },
  ];

  return (
    <div className='investor-profile-section'>
      <div className='profile-info-top-container'>
        <div className='profile-image'>
          <img src={randomProfileImageURL()} alt='' />
        </div>
        <div className='info'>
          <p className='title'>Freddie Bechtelar</p>
          <p className='title'>Food Scientist at Nestlé</p>
          <p className='tags'>SPONSOR</p>
        </div>
      </div>

      <div className='investor-bio-container'>
        <p className='title'>About</p>
        <p>
          Hello, I'm Freddie. I work at Nestlé, where I enjoy working in the
          field of food science. My job includes helping design food products
          that are healthy and taste good. It's very rewarding for me to see
          people enjoying what I've developed!
        </p>
        <p className='title'>I'm here to</p>
        <p>
          I want to be able to help students on their journey of figuring out
          what they want to do with their life so they can find a career path
          that fits them; including an internship or job opportunity with Nestlé
          if that's something they're interested in.
        </p>
        <p className='title'>Experiences</p>
        <p>
          Education: Bachelor at University of Fribourg; Master at LSE 10+ years
          of experience in the food industry Experience managing product
          development teams and gained expertise in scaling up various projects
          Conducted degreed research on food ingredients and developed new
          productst
        </p>
        <p className='title'>Why I am supporting students</p>
        <p>
          I used to be a student myself and how much I cared about every penny.
        </p>
      </div>

      <div className='sponsored-talents-container'>
        <p className='title'>Total contribution in USD 11000</p>
        <p className='title'>Those who I supported</p>
        <div className='talent-cards-container'>
          {supportedTalents.map((talent) => (
            <div className='talent-card'>
              <img src={talent.profileImageURL} alt='' />
              <p>{talent.name}</p>
              <p>USD {talent.amount}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvestorProfile;
