interface Props {
  title: string;
  onClick: () => void;
  className?: string;
}

const PrimaryButton: React.FC<Props> = ({ title, onClick, className }) => {
  return (
    <button
      className={`bg-primary-700 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded ${className}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};
export default PrimaryButton;
