import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { StringParam, useQueryParam } from 'use-query-params';
import { AuthContext } from '../provider/AuthProvider';
import LoadingPage from './LoadingPage';

const Callback: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();
  const { fetchTokensWithAuthorizationCode } = useContext(AuthContext);
  // const [autorizationCode] = useQueryParam('code', StringParam);

  let [searchParams, setSearchParams] = useSearchParams();

  console.log(searchParams.get('code'));

  useEffect(() => {
    const handleCallback = async () => {
      try {
        await fetchTokensWithAuthorizationCode(searchParams.get('code')!);
        console.log('after fetching');

        setTimeout(() => {
          navigate('/');
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    };
    if (searchParams.get('code')) {
      handleCallback();
    }
  }, [searchParams, fetchTokensWithAuthorizationCode, navigate]);

  return <LoadingPage />;
};

export default Callback;
