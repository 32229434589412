import React, { useContext, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
  createURL,
  createAuthHeader,
  fetcherAuth,
  showToast,
  uploadWithPresignedPostURL,
} from '../helper';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';
import { IPost } from '../models/post';
import { IComment } from '../models/comment';
import { ILike } from '../models/like';
import { AuthContext } from '../provider/AuthProvider';
import FileSelector from './FileSelector';

const PostEdit: React.FunctionComponent<{}> = () => {
  const { postID } = useParams<{
    postID: string;
  }>();
  const navigate = useNavigate();
  const { userID, getAccessToken } = useContext(AuthContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [editPost, setEditPost] = useState<IPost>({
    _id: 'new',
    userID: '',
    bodyText: '',
    media: [],
    timestamp: 0,
  });
  const [filesForNewPost, setFilesForNewPost] = useState<File[]>([]);
  // const fileInputRef = useRef<HTMLInputElement>(null);

  const postURL: string = createURL(`posts/${postID}`);
  const { data: postData, error: postDataError } = useSWR<{
    post: IPost;
    likes: ILike[];
    comments: IComment[];
    mediaURLs: { url: string; s3Url: string }[];
  }>(postID !== 'new' ? postURL : null, async (url) =>
    fetcherAuth(url, await getAccessToken())
  );

  useEffect(() => {
    if (!postData || isEditing) return;
    setEditPost(postData.post);
  }, [postData, isEditing]);

  useEffect(() => {
    if (postDataError && postID !== 'new') {
      navigate(`/`);
    }
  }, [postDataError, navigate, postID]);

  const handleSavePost = async () => {
    if (!editPost.bodyText) {
      showToast('Cannot leave body text empty.', 'error');
      return;
    }
    if (isProcessing) return;

    if (postID === 'new') {
      //create new post
      await processPostUpload();
    } else {
      //update post
      await updatePost();
    }
  };

  const processPostUpload = async () => {
    try {
      const backendURL: string = createURL(`posts`);
      setIsProcessing(true);
      const filenames: { url: string; type: string }[] = [];
      for (const file of filesForNewPost) {
        const { status, filename } = await uploadWithPresignedPostURL(
          file,
          setUploadPercentage,
          true,
          await getAccessToken()
        );

        if (status === 204) {
          filenames.push({ url: filename, type: file.type });
        } else {
          throw Error('Upload failed');
        }
      }

      const newPost = {
        _id: 'new',
        userID: userID,
        bodyText: editPost.bodyText,
        media: filenames,
        timestamp: 0,
      };

      const res: AxiosResponse = await axios.post(
        backendURL,
        newPost,
        createAuthHeader(await getAccessToken())
      );

      if (res) {
        showToast('Post created');

        // setFilesForNewPost([]);
        // setEditPost((prevPost) => ({ ...prevPost, bodyText: '', media: [] }));

        navigate(`/`);
      }
    } catch (error) {
      console.log(error);
    }
    setIsProcessing(false);
  };

  const updatePost = async () => {
    if (!editPost.bodyText) {
      showToast('Cannot leave body text empty.', 'error');
      return;
    }
    if (isProcessing) return;

    const backendURL: string = createURL(`posts/${postID}`);
    try {
      setIsProcessing(true);

      const newPost = {
        _id: postID,
        userID: userID,
        bodyText: editPost.bodyText,
        media: [],
        timestamp: 0,
      };

      const res: AxiosResponse = await axios.put(
        backendURL,
        newPost,
        createAuthHeader(await getAccessToken())
      );

      if (res) {
        showToast('Saved changes.');
        setIsEditing(false);
      }
    } catch (error) {
      console.log(error);
    }
    setIsProcessing(false);
  };

  const deletePost = async () => {
    if (postID === 'new' || isProcessing) return;

    const backendURL: string = createURL(`posts/${postID}`);
    try {
      setIsProcessing(true);

      const res: AxiosResponse = await axios.delete(
        backendURL,
        createAuthHeader(await getAccessToken())
      );

      if (res) {
        showToast('Deleted post.');
        // mutate(`organisations/${organisationID}/feed/main/posts/feed?offset=0`);
        navigate(`/`);
      }
    } catch (error) {
      console.log(error);
    }
    setIsProcessing(false);
  };

  if (postDataError) console.log('Failed to load post data.');

  return (
    <>
      <div className='bg-gray-800 p-2 '>
        <div className='bg-white'>
          <div className='border-b border-gray-300 py-1 pl-2'>
            <p className='text-base font-bold'>Neuer Beitrag</p>
          </div>
          <div className='flex p-2 py-2'>
            <img
              className='ml-1 mr-3 h-16 w-16 rounded-full object-cover'
              src={'/assets/images/perseedu-logo.png'}
              alt=''
            />
            <div>
              <p className='text-base font-bold'>Anna Andaloro</p>
              <p className='text-base text-gray-500'>15:34</p>
            </div>
          </div>

          <div className='px-2'>
            <textarea
              className='w-full rounded-lg border px-3 py-2 text-gray-700 focus:outline-none'
              rows={4}
              value={editPost.bodyText}
              onChange={(event) => {
                setIsEditing(true);
                setEditPost((post) => ({
                  ...post,
                  bodyText: event.target.value || '',
                }));
              }}
              placeholder='What do you want to share?'
            />
          </div>

          <p className='px-2 text-base font-bold'>Add Media</p>

          <FileSelector setSelectedFiles={setFilesForNewPost} />
          <p>{uploadPercentage}</p>
          <div className='flex justify-end p-3'>
            <button
              className='rounded bg-black px-3 py-2 text-white'
              onClick={() => handleSavePost()}
            >
              {postID === 'new' ? 'Publish' : 'Save changes'}
            </button>
          </div>

          {userID === postData?.post.userID && (
            <div className='flex justify-end p-3'>
              <button
                className='rounded bg-red-600 px-3 py-2 text-white'
                onClick={() => deletePost()}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default PostEdit;
