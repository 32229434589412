import { ReactNode, useContext } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { AuthContext } from '../provider/AuthProvider';

interface Props {
  children: ReactNode;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  //   const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  //   if (isLoading) return <LoadingPage />;

  //   if (!isAuthenticated) {
  //     loginWithRedirect();
  //     return <LoadingPage />;
  //   }

  //   return <>{children}</>;

  //   const navigate = useNavigate();
  let location = useLocation();

  // const navigate = useNavigate();

  // useEffect(() => {
  //   const refreshToken = localStorage.getItem('refresh_Token');
  //   if (!refreshToken) {
  //     navigate('/login');
  //   }
  // }, [navigate]);

  const { isLoggedIn } = useContext(AuthContext);

  if (!isLoggedIn()) {
    //     //TODO: save redirectURI to localstorage, which gets intercepted from the callback path
    //     // localStorage.setItem('redirectURI', location)
    sessionStorage.setItem('redirectURI', location.pathname);

    console.log('protected navigate to login');

    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  //   if (!refreshToken) {
  //     //TODO: save redirectURI to localstorage, which gets intercepted from the callback path
  //     // localStorage.setItem('redirectURI', location)
  //     // Navigate()
  //     // return <Navigate to="/login" state={{ from: location }} replace />;
  //     return <div></div>;
  //   }

  // if (!auth.user) {
  //   // Redirect them to the /login page, but save the current location they were
  //   // trying to go to when they were redirected. This allows us to send them
  //   // along to that page after they login, which is a nicer user experience
  //   // than dropping them off on the home page.
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  //   return children;
  return <>{children}</>;
};

export default ProtectedRoute;

// function RequireAuth({ children }: { children: JSX.Element }) {
//   // let auth = useAuth();
//   let location = useLocation();

//   // const navigate = useNavigate();

//   // useEffect(() => {
//   //   const refreshToken = localStorage.getItem('refresh_Token');
//   //   if (!refreshToken) {
//   //     navigate('/login');
//   //   }
//   // }, [navigate]);

//   const refreshToken = localStorage.getItem('refresh_Token');

//   console.log(location);

//   if (!refreshToken) {
//     //TODO: save redirectURI to localstorage, which gets intercepted from the callback path
//     // localStorage.setItem('redirectURI', location)
//     // Navigate()
//     // return <Navigate to="/login" state={{ from: location }} replace />;
//   }

//   // if (!auth.user) {
//   //   // Redirect them to the /login page, but save the current location they were
//   //   // trying to go to when they were redirected. This allows us to send them
//   //   // along to that page after they login, which is a nicer user experience
//   //   // than dropping them off on the home page.
//   //   return <Navigate to="/login" state={{ from: location }} replace />;
//   // }

//   return children;
// }
