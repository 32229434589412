// import { initializeConnector } from '@web3-react/core';
// import { MetaMask } from '@web3-react/metamask';

// export const [metaMask, hooks] = initializeConnector<MetaMask>(
//   (actions) => new MetaMask(actions)
// );

import { InjectedConnector } from '@web3-react/injected-connector';

export const metamaskProvider = new InjectedConnector({
  supportedChainIds: [97], // bsc testnet
  // supportedChainIds: [1337], //localhost
});
