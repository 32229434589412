import axios from 'axios';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createAuthHeader,
  createS3ImageURL,
  createURL,
  fetcherAuth,
} from '../helper';
import { AuthContext } from '../provider/AuthProvider';
import useSWR from 'swr';
import { IUser } from '../models/user';
import { IUserProfile } from '../models/userProfile';
import Feed from './Feed';

const Explore = () => {
  const navigate = useNavigate();
  const { logout, getAccessToken, isLoggedIn } = useContext(AuthContext);

  const userURL: string = createURL(`users/`);
  const { data: user, error: userError } = useSWR<IUser>(userURL, async (url) =>
    fetcherAuth(url, await getAccessToken())
  );

  const userProfilesURL: string = createURL(`userProfiles`);
  const { data: userProfiles, error: userProfilesError } = useSWR<
    IUserProfile[]
  >(userProfilesURL, async (url) => fetcherAuth(url, await getAccessToken()));

  // useEffect(() => {
  //   console.log('useEffect dashboard');
  //   if (!isLoggedIn) {
  //     console.log('dashboard navigate to login');
  //     navigate('/login');
  //   }
  // }, [navigate, isLoggedIn]);

  useEffect(() => {
    if (userError?.response?.data === 'User was null') {
      navigate('/newUser');
    }
  }, [userError, navigate]);

  const deleteUser = async () => {
    const backendURL: string = createURL('users');
    try {
      const res = await axios.delete(
        backendURL,
        createAuthHeader(await getAccessToken())
      );

      if (res.status === 200) {
        console.log('Successfully deleted user.');
        logout();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (userProfilesError) console.log('Failed to load user profiles.');

  return (
    <div className='bg-gray-100 p-2'>
      <p
        className='font-bold'
        onClick={async () => console.log(`Bearer ${await getAccessToken()}`)}
      >
        Explore Talents
      </p>

      <div className='flex w-full flex-col gap-2 '>
        {userProfiles &&
          userProfiles.map((profile) => (
            <div
              key={profile.userID}
              className='flex w-full cursor-pointer rounded bg-white p-2 shadow'
              onClick={() => navigate(`/profiles/${profile.userID}`)}
            >
              <div className='mr-2 h-20 w-20 flex-shrink-0 overflow-hidden rounded-full bg-gray-200'>
                <img
                  className='h-20 w-20 object-cover'
                  src={
                    profile.profileImageURL
                      ? createS3ImageURL(profile.profileImageURL)
                      : '../assets/images/earth.jpg'
                  }
                  alt=''
                />
              </div>
              <div className='flex flex-col'>
                <p className='font-bold'>
                  {profile.firstName} {profile.lastName}
                </p>
                <p className='text-sm'>{profile.bio}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Explore;
