import { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
  handleClose: () => void;
  show: boolean;
  children: any;
}

export const Modal: FunctionComponent<Props> = ({
  handleClose,
  show,
  children,
}) => {
  const modalRoot = document.getElementById('modal-root');
  if (!modalRoot) return null;

  return show
    ? ReactDOM.createPortal(
        <div className='modal display-block px-1'>
          <section className='modal-main'>
            <div className='close-action'>
              <button className='basic-button' onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            {children}
          </section>
        </div>,
        modalRoot
      )
    : null;
};

export default Modal;

// import React, { FunctionComponent } from 'react';
// import ReactDOM from 'react-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';

// interface Props {
//   handleClose: () => void;
//   title: string;
//   show: boolean;
//   hideCancelButton?: boolean;
//   children: any;
// }

// export const Modal: FunctionComponent<Props> = ({
//   handleClose,
//   title,
//   show,
//   hideCancelButton = false,
//   children,
// }) => {
//   const isVisible = show
//     ? 'modal modal-display-block'
//     : 'modal modal-display-none';

//   return (
//     <ModalSkelleton>
//       <div className={isVisible}>
//         {/* <div className='modal-background' /> */}
//         <div className='modal-main'>
//           <div className='modal-header'>
//             <div className='modal-title'>
//               <p>{title}</p>
//             </div>
//             {!hideCancelButton && (
//               <div className='close-action'>
//                 <button onClick={handleClose}>
//                   <FontAwesomeIcon icon={faTimes} />
//                 </button>
//               </div>
//             )}
//           </div>
//           <div className='modal-body-container'>{children}</div>
//         </div>
//       </div>
//     </ModalSkelleton>
//   );
// };

// interface SkelletonProps {
//   children: any;
// }

// const modalRoot = document.getElementById('modal-root');

// interface State {
//   el: HTMLDivElement;
// }

// class ModalSkelleton extends React.Component<SkelletonProps, State> {
//   state: State = {
//     el: document.createElement('div'),
//   };
//   // constructor(props: Readonly<Props>) {
//   //   super(props);
//   //   this.el = document.createElement('div');
//   // }

//   componentDidMount() {
//     // The portal element is inserted in the DOM tree after
//     // the Modal's children are mounted, meaning that children
//     // will be mounted on a detached DOM node. If a child
//     // component requires to be attached to the DOM tree
//     // immediately when mounted, for example to measure a
//     // DOM node, or uses 'autoFocus' in a descendant, add
//     // state to Modal and only render the children when Modal
//     // is inserted in the DOM tree.
//     modalRoot!.appendChild(this.state.el);
//   }

//   componentWillUnmount() {
//     modalRoot!.removeChild(this.state.el);
//   }

//   render() {
//     return ReactDOM.createPortal(this.props.children, this.state.el);
//   }
// }

// export default Modal;
