interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  name: string;
  value: boolean;
}

const PrimaryCheckbox: React.FC<Props> = ({ onChange, label, name, value }) => {
  return (
    <div className='form-check'>
      <input
        className='form-check-inputs float-left mt-1 mr-2 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat align-top text-black transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none'
        type='checkbox'
        name={name}
        checked={value}
        onChange={onChange}
      />
      <label className='form-check-label inline-block text-gray-800'>
        {label}
      </label>
    </div>
  );
};

export default PrimaryCheckbox;
