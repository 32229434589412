import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAdjust,
  faBook,
  faCompass,
  faHeart,
  faHouse,
  faPerson,
  faPersonBiking,
  faPersonBooth,
  faPersonDotsFromLine,
  faPray,
  faTimes,
  faTrash,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnyNsRecord } from 'dns';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router';
import { AuthContext } from '../provider/AuthProvider';
import PrimaryButton from './buttons/PrimaryButton';

const BottomTabBar = () => {
  const navigate = useNavigate();
  const { userID, logout, getAccessToken } = useContext(AuthContext);

  const Tab: FC<{ path: string; icon: IconProp }> = ({ path, icon }) => {
    return (
      <div
        className='flex cursor-pointer items-center rounded p-5 hover:bg-gray-500'
        onClick={async () => {
          navigate(path);
          console.log(await getAccessToken());
        }}
      >
        <FontAwesomeIcon icon={icon} style={{ marginRight: '6px' }} />
      </div>
    );
  };

  return (
    <div className='fixed bottom-0 flex h-14 w-full  items-center justify-between border-t-2 border-gray-500 bg-white p-2 px-10'>
      <Tab path='home' icon={faHouse} />
      <Tab path={`profiles/${userID}`} icon={faUser} />
      <Tab path='explore' icon={faCompass} />
      <Tab path='heart' icon={faHeart} />
    </div>

    //   <div className='md:hidden flex items-center justify-between w-full px-2 '>
    //     <p>perseedU</p>
    //     <FontAwesomeIcon icon={faTimes} />
    //   </div>

    //   <div className='hidden md:flex flex-col w-full bg-white h-screen'>
    //     <div className='flex items-center justify-center bg-blue-800 h-16'>
    //       <img
    //         className='w-32'
    //         src='assets/images/perseedu-logo.png'
    //         alt='perseedu-logo'
    //       />
    //     </div>

    //     <div className='flex bg-blue-200 h-20 items-center justify-start'>
    //       <div className='pl-2 flex items-center justify-center'>
    //         <img
    //           className='w-14 h-14 object-cover rounded-full ml-1 mr-3'
    //           src='assets/images/profile.png'
    //           alt='user-profile-image'
    //         />
    //       </div>
    //       <div>
    //         <p className='font-bold text-sm'>John Smith</p>
    //         <p className='text-xs'>mail@mail.mail</p>
    //       </div>
    //     </div>

    //     <Link title='Dashboard' path='/' icon={faAdjust} />
    //     <Link
    //       title='Your Profile'
    //       path={`/profiles/${userID}`}
    //       icon={faPray}
    //     />

    //     <button className='orange-button' onClick={() => logout()}>
    //       Logout
    //     </button>
    //   </div>
    // </>
  );
};
export default BottomTabBar;
