interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  placeholder?: string;
  autoComplete?: string;
  type?: string;
}

const PrimaryInput: React.FC<Props> = ({
  onChange,
  name,
  placeholder,
  value,
  autoComplete,
  type,
}) => {
  return (
    <input
      className='mb-4 w-full border-b-2 border-none border-white bg-primary-300 px-2 py-3 text-white placeholder-gray-300 focus:border-orange-500 focus:outline-none'
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoComplete={autoComplete}
      type={type}
    />
  );
};

export default PrimaryInput;
