import { BrowserRouter } from 'react-router-dom';
import { Routes, Route, Outlet } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { QueryParamProvider } from 'use-query-params';
import { AuthProvider } from './provider/AuthProvider';
import RoutesController from './router/RoutesController';
import './styles/style.css';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './router/ProtectedRoute';
import Login from './components/Login';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';

const App = () => {
  function getLibrary(provider: any, connector: any) {
    return new Web3(provider); // this will vary according to whether you use e.g. ethers or web3.js
  }

  return (
    <BrowserRouter>
      {/* <QueryParamProvider ReactRouterRoute={Route}> */}
      <Web3ReactProvider getLibrary={getLibrary}>
        <AuthProvider>
          <ToastContainer theme='dark' />

          {/* <Routes>
          <Route path='/' element={<ParentComponent />}>
            <Route path='child' element={<ChildComponent />} />
          </Route>
        </Routes> */}

          <RoutesController />
        </AuthProvider>
      </Web3ReactProvider>

      {/* </QueryParamProvider> */}
    </BrowserRouter>
  );
};

export default App;

// function ParentComponent() {
//   return (
//     <div>
//       <h1>i am parent component</h1>
//       <Outlet />
//     </div>
//   );
// }
// function ChildComponent() {
//   return (
//     <div>
//       <h1>i am child component</h1>
//     </div>
//   );
// }
