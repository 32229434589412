import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import Callback from '../components/Callback';
import Login from '../components/Login';
import Logout from '../components/Logout';
import NewUser from '../components/NewUser';
import TokenCalculator from '../components/TokenCalculator';
import UserOnboarding from '../components/UserOnboarding';
import VerifyEmail from '../components/VerifyEmail';
import MainApp from './MainApp';

import Dashboard from '../components/Dashboard';
import InvestorProfile from '../components/InvestorProfile';
import PostEdit from '../components/PostEdit';
import Sidebar from '../components/Sidebar';
import TalentProfile from '../components/TalentProfile';
import Talents from '../components/Talents';
import UserProfile from '../components/UserProfile';
import ProtectedRoute from './ProtectedRoute';
import Topbar from '../components/Topbar';
import BottomTabBar from '../components/BottomTabBar';
import Explore from '../components/Explore';
import Portfolio from '../components/Portfolio';
import AdminDashboard from '../components/AdminDashboard';
import { useEffect } from 'react';

const RoutesController = () => {
  return (
    <Routes>
      <Route path='/callback' element={<Callback />} />
      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/tokenCalculator' element={<TokenCalculator />} />

      <Route
        path='/newUser'
        element={
          <ProtectedRoute>
            <NewUser />
          </ProtectedRoute>
        }
      />
      <Route
        path='/onboardUser'
        element={
          <ProtectedRoute>
            <UserOnboarding />
          </ProtectedRoute>
        }
      />
      <Route path='/verifyEmail' element={<VerifyEmail />} />

      {/* PAGES WITH SIDEBAR */}
      <Route
        path='/'
        element={
          <ProtectedRoute>
            <div className='w-full flex-col bg-gray-100 md:flex md:min-h-screen md:flex-row'>
              <div className='flex h-10 w-full items-center justify-between bg-gray-300 md:h-screen md:w-60 md:items-start'>
                <Sidebar />
              </div>

              <BottomTabBar />

              <div className='w-full'>
                {/* <div className='hidden md:inline'>
                  <Topbar />
                </div> */}

                <Outlet />
              </div>
            </div>
          </ProtectedRoute>
        }
      >
        <Route index element={<Explore />} />
        {/* <Route index element={<Dashboard />} /> */}

        <Route path={'explore'} element={<Explore />} />
        <Route path={'home'} element={<Portfolio />} />
        <Route path={'heart'} element={<Portfolio />} />
        <Route path={'admin'} element={<AdminDashboard />} />

        <Route path={'profiles/:userID'} element={<UserProfile />} />
        <Route path={'talents/:talentID'} element={<TalentProfile />} />
        <Route path={'talents'} element={<Talents />} />
        <Route path={'investors/:investorID'} element={<InvestorProfile />} />
        <Route path={'newPost/:postID'} element={<PostEdit />} />
      </Route>
    </Routes>
  );
};

export default RoutesController;
