import { ReactNode } from 'react';

interface Props {
  onClick: () => void;
  className?: string;
  children: ReactNode;
}

const PillButton: React.FC<Props> = ({ onClick, className, children }) => {
  return (
    <button
      className={`hover:bg-blue-dark flex items-center rounded-full bg-white py-2 px-4 font-semibold text-primary-700 hover:bg-gray-300 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
export default PillButton;
