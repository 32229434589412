import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  createAuthHeader,
  createS3ImageURL,
  createURL,
  fetcherAuth,
  showToast,
} from '../helper';
import { AuthContext } from '../provider/AuthProvider';
import useSWR, { useSWRConfig } from 'swr';
import Modal from './Modal';
import { IUserProfile } from '../models/userProfile';
import PrimaryButton from './buttons/PrimaryButton';
import {
  faCheckCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PrimaryCheckbox from './PrimaryCheckbox';
import { ITalentToken } from '../models/talentToken';
import Web3 from 'web3';
import { STUDENT_TOKEN_CONTRACT_ABI } from '../contracts/StudentToken';
import { AbiItem } from 'web3-utils';
import BN from 'bn.js';
import {
  SEED_TOKEN_CONTRACT,
  SEED_TOKEN_CONTRACT_ABI,
} from '../contracts/SeedTokenABI';
import { IPortfolio } from '../models/portfolio';
import { useWeb3React } from '@web3-react/core';
import { metamaskProvider } from '../connectors/metaMask';

const UserProfile = () => {
  const { mutate } = useSWRConfig();
  const { activate, active, deactivate, account, connector, library, chainId } =
    useWeb3React();
  const { getAccessToken, userID: deviceUserID } = useContext(AuthContext);
  const { userID } = useParams<{ userID: string }>();

  const [currentTab, setCurrentTab] = useState('info');
  const [showEditUserProfileModal, setShowEditUserProfileModal] =
    useState(false);
  const [showEditPerkModal, setShowEditPerkModal] = useState(false);
  const [selectedPerkID, setSelectedPerkID] = useState(0);
  const [showEditMilestoneModal, setShowEditMilestoneModal] = useState(false);
  const [selectedMilestoneID, setSelectedMilestoneID] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [transactionTypeIsBUY, setTransactionTypeIsBUY] = useState(true);
  const [transactionAmount, setTransactionAmount] = useState(0);
  const [accountSEEDBalance, setAccountSEEDBalance] = useState('0');
  const [accountTalentTokenBalance, setAccountTalentTokenBalance] =
    useState('0');
  const [showEditWalletAddressModal, setShowEditWalletAddressModal] =
    useState(false);
  const [editWalletAddress, setEditWalletAddress] = useState('');

  const [editUserProfile, setEditUserProfile] = useState<IUserProfile>({
    userID: '',
    firstName: '',
    lastName: '',
    profileImageURL: '',

    coverImageURL: '',
    categories: '',
    passions: '',
    bio: '',
    about: '',

    milestones: [],
    perks: [],
  });
  const [editPortfolio, setEditPortfolio] = useState<IPortfolio>({
    _id: 'new',
    userID: '',
    tokens: [],
  });

  const userProfileURL: string = createURL(`userProfiles/${userID}`);
  const { data: userProfile, error: userProfileError } = useSWR<IUserProfile>(
    userProfileURL,
    async (url) => fetcherAuth(url, await getAccessToken())
  );

  const talentTokenURL: string = createURL(`talentTokens/${userID}`);
  const { data: talentToken, error: talentTokenError } = useSWR<ITalentToken>(
    userID ? talentTokenURL : null,
    async (url) => fetcherAuth(url, await getAccessToken())
  );

  const portfolioURL: string = createURL(`portfolios`);
  const { data: portfolio, error: portfolioError } = useSWR<IPortfolio>(
    portfolioURL,
    async (url) => fetcherAuth(url, await getAccessToken())
  );

  const followerIDsURL: string = createURL(
    `followerRelationships/followers/${userID}`
  );
  const { data: followerIDs, error: followerIDsError } = useSWR<string[]>(
    followerIDsURL,
    async (url) => fetcherAuth(url, await getAccessToken())
  );

  const followingIDsURL: string = createURL(
    `followerRelationships/following/${userID}`
  );
  const { data: followingIDs, error: followingIDsError } = useSWR<string[]>(
    followingIDsURL,
    async (url) => fetcherAuth(url, await getAccessToken())
  );

  useEffect(() => {
    if (userProfile && !isEditing) {
      setEditUserProfile(userProfile);
    }
  }, [isEditing, userProfile]);

  useEffect(() => {
    if (portfolio && !editPortfolio.userID) {
      setEditPortfolio(portfolio);
    }
  }, [portfolio, editPortfolio]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setIsEditing(true);

    if (name === 'profileImage') {
      if (event.target.files) {
        setProfileImageFile(event.target.files[0]);
      }
      return;
    }
    if (name === 'perk-name') {
      setEditUserProfile((prevProfile) => ({
        ...prevProfile,
        perks: prevProfile.perks.map((perk) =>
          perk.position === selectedPerkID ? { ...perk, name: value } : perk
        ),
      }));
      return;
    }
    if (name === 'perk-holdingAmount') {
      setEditUserProfile((prevProfile) => ({
        ...prevProfile,
        perks: prevProfile.perks.map((perk) =>
          perk.position === selectedPerkID
            ? { ...perk, holdingAmount: parseInt(value) || 0 }
            : perk
        ),
      }));
      return;
    }
    if (name === 'milestone-name') {
      setEditUserProfile((prevProfile) => ({
        ...prevProfile,
        milestones: prevProfile.milestones.map((milestone) =>
          milestone.position === selectedPerkID
            ? { ...milestone, name: value }
            : milestone
        ),
      }));
      return;
    }
    if (name === 'transactionAmount') {
      setTransactionAmount(parseFloat(value) || 0);
      return;
    }

    setEditUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleChangeTextArea = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setIsEditing(true);

    if (name === 'perk-description') {
      setEditUserProfile((prevProfile) => ({
        ...prevProfile,
        perks: prevProfile.perks.map((perk) =>
          perk.position === selectedPerkID
            ? { ...perk, description: value }
            : perk
        ),
      }));
      return;
    }
    if (name === 'milestone-description') {
      setEditUserProfile((prevProfile) => ({
        ...prevProfile,
        milestones: prevProfile.milestones.map((milestone) =>
          milestone.position === selectedPerkID
            ? { ...milestone, description: value }
            : milestone
        ),
      }));
      return;
    }

    setEditUserProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const toggleFollowUser = async () => {
    if (deviceUserID === userID) {
      console.log('Cannot follow own userID.');
      return;
    }
    if (!followerIDs) return;
    try {
      const backendURL: string = createURL('followerRelationships/follow');
      const res = await axios.post(
        backendURL,
        { userID: userID },
        createAuthHeader(await getAccessToken())
      );
      if (res.status === 200) {
        const didRemoveFollow = followerIDs.some((id) => deviceUserID === id);
        var newFollowerIDs: string[] = [];
        if (didRemoveFollow) {
          newFollowerIDs = followerIDs.filter((id) => id !== userID);
        } else {
          newFollowerIDs = [...followerIDs, deviceUserID];
        }
        mutate(followerIDsURL, newFollowerIDs, true);
        console.log('Successfully toggled follow state.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveUserProfile = async () => {
    if (!editUserProfile.firstName || !editUserProfile.lastName) {
      console.log('Firstname or lastname cannot be empty.');
      return;
    }

    try {
      const backendURL: string = createURL(`userProfiles`);
      const res = await axios.put(
        backendURL,
        editUserProfile,
        createAuthHeader(await getAccessToken())
      );
      if (res.status === 200) {
        console.log('Saving successful.');
        setIsEditing(false);
        setShowEditUserProfileModal(false);
        setProfileImageFile(undefined);
        mutate(userProfileURL, editUserProfile, true);
        showToast('Saved.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [profileImageFile, setProfileImageFile] = useState<File>();

  const uploadProfileImage = async () => {
    try {
      if (!profileImageFile) {
        console.log('bitte logo selecten');
        return;
      }

      // if (isProcessing) return;

      const backendURL: string = createURL(`aux/signedPostData`);

      // setIsProcessing(true);
      const { data: presignedPostData } = await axios.post(
        backendURL,
        { filename: profileImageFile.name },
        createAuthHeader(await getAccessToken())
      );

      const filename = presignedPostData.filename;

      const formData = new FormData();
      Object.keys(presignedPostData.fields).forEach((key) => {
        formData.append(key, presignedPostData.fields[key]);
      });
      formData.append('file', profileImageFile);

      const { status }: AxiosResponse = await axios.post(
        presignedPostData.url,
        formData
      );

      if (status === 204) {
        const backendURL = createURL(`userProfiles/profileImage`);

        await axios.put(
          backendURL,
          {
            filename: filename,
          },
          createAuthHeader(await getAccessToken())
        );
        mutate(userProfileURL, {}, { revalidate: true });
        showToast('Successfully updated profile image.');

        // window.flash('Upload erfolgreich.', 'success');
      } else {
        throw new Error('Status code of S3 upload was not 204.');
      }
    } catch (error) {
      console.log(error);

      // window.flash('Upload fehlgeschlagen.', 'error');
    }
    // setIsProcessing(false);
  };

  const handleAddNewPerk = () => {
    if (!userProfile) return;
    setShowEditPerkModal(true);
    setSelectedPerkID(userProfile?.perks.length || 0);
    setEditUserProfile((prevProfile) => ({
      ...prevProfile,
      perks: [
        ...prevProfile.perks,
        {
          position: prevProfile.perks.length,
          name: '',
          description: '',
          holdingAmount: 0,
        },
      ],
    }));
  };

  const savePerk = () => {
    setShowEditPerkModal(false);
    handleSaveUserProfile();
  };

  const deletePerk = async () => {
    if (editUserProfile.perks.length - 1 < selectedPerkID) return;
    setEditUserProfile((prevProfile) => ({
      ...prevProfile,
      perks: prevProfile.perks.filter((p) => p.position !== selectedPerkID),
    }));

    const backendURL: string = createURL(`userProfiles`);
    const res = await axios.put(
      backendURL,
      {
        ...editUserProfile,
        perks: editUserProfile.perks.filter(
          (p) => p.position !== selectedPerkID
        ),
      },
      createAuthHeader(await getAccessToken())
    );

    setShowEditPerkModal(false);
  };

  const handleEditPerkClick = (perkID: number) => {
    setSelectedPerkID(perkID);
    setShowEditPerkModal(true);
  };

  const handleAddNewMilestone = () => {
    if (!userProfile) return;
    setShowEditMilestoneModal(true);
    setSelectedPerkID(userProfile?.milestones.length || 0);
    setEditUserProfile((prevProfile) => ({
      ...prevProfile,
      milestones: [
        ...prevProfile.milestones,
        {
          position: prevProfile.milestones.length,
          name: '',
          description: '',
          completed: false,
        },
      ],
    }));
  };

  const saveMilestone = () => {
    setShowEditMilestoneModal(false);
    handleSaveUserProfile();
  };

  const saveWalletAddress = async () => {
    try {
      const backendURL: string = createURL(`users`);
      const res = await axios.put(
        backendURL,
        {
          walletAddress: editWalletAddress,
        },
        createAuthHeader(await getAccessToken())
      );

      showToast('Successfully saved.');
    } catch (error) {
      console.log('Failed');
    }
  };

  const deleteMilestone = async () => {
    if (editUserProfile.milestones.length - 1 < selectedMilestoneID) return;
    setEditUserProfile((prevProfile) => ({
      ...prevProfile,
      milestones: prevProfile.milestones.filter(
        (p) => p.position !== selectedPerkID
      ),
    }));

    const backendURL: string = createURL(`userProfiles`);
    const res = await axios.put(
      backendURL,
      {
        ...editUserProfile,
        milestones: editUserProfile.milestones.filter(
          (p) => p.position !== selectedPerkID
        ),
      },
      createAuthHeader(await getAccessToken())
    );

    // handleSaveUserProfile();
    setShowEditMilestoneModal(false);
  };

  const handleEditMilestoneClick = (milestoneID: number) => {
    setSelectedMilestoneID(milestoneID);
    setShowEditMilestoneModal(true);
  };

  const toggleMilestoneCompletionStatus = () => {
    setEditUserProfile((prevProfile) => ({
      ...prevProfile,
      milestones: prevProfile.milestones.map((m) =>
        m.position === selectedMilestoneID
          ? { ...m, completed: !m.completed }
          : m
      ),
    }));
  };

  const [buyPriceInSEED, setBuyPriceInSEED] = useState('0');
  const [sellPriceInSEED, setSellPriceInSEED] = useState('0');

  useEffect(() => {
    const calculatePriceInSEED = async () => {
      try {
        const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');

        const studentToken = new web3.eth.Contract(
          STUDENT_TOKEN_CONTRACT_ABI as AbiItem[],
          talentToken?.contractAddress
        );
        const studentTokenTotalSupply = await studentToken.methods
          .totalSupply()
          .call();

        const buyPrice = calculateTalentTokenBuyPriceInSEED(
          studentTokenTotalSupply,
          transactionAmount.toString()
        );
        setBuyPriceInSEED(buyPrice.toString());

        const sellPrice = calculateTalentTokenSellPriceInSEED(
          studentTokenTotalSupply,
          transactionAmount.toString()
        );
        setSellPriceInSEED(sellPrice.toString());

        const tokenInst = new web3.eth.Contract(
          SEED_TOKEN_CONTRACT_ABI as AbiItem[],
          SEED_TOKEN_CONTRACT
        );
        const accounts = await web3.eth.requestAccounts();

        const SEEDbalance = await tokenInst.methods
          .balanceOf(accounts[0])
          .call();
        setAccountSEEDBalance(SEEDbalance);

        const studentTokenBalance = await studentToken.methods
          .balanceOf(accounts[0])
          .call();
        setAccountTalentTokenBalance(studentTokenBalance);
      } catch (error) {
        console.log(error);
        // return '0';
      }
    };
    calculatePriceInSEED();
  }, [transactionAmount]);

  const calculateTalentTokenBuyPriceInSEED = (
    totalSupply: string,
    buyAmount: string
  ) => {
    // Bonding curve: price(supply) = 0.03 * supply
    // Intergal: 0.03 / 2 * [(supply+_amountStudentToken)^2 - (supply)^2]

    const buyAmountInWei = Web3.utils.toWei(buyAmount, 'ether');

    const A1 = new BN(totalSupply).add(new BN(buyAmountInWei)).pow(new BN(2));
    const A2 = new BN(totalSupply).pow(new BN(2));
    const A = A1.sub(A2);
    const B = new BN(3).mul(A).div(new BN(2));
    const C = B.div(new BN(10).pow(new BN(20)));
    return C;
  };

  const calculateTalentTokenSellPriceInSEED = (
    totalSupply: string,
    sellAmount: string
  ) => {
    // Bonding curve: price(supply) = 0.03 * supply
    // Intergal: 0.03 / 2 * [(supply+_amountStudentToken)^2 - (supply)^2]

    const sellAmountInWei = Web3.utils.toWei(sellAmount, 'ether');

    const A1 = new BN(totalSupply).pow(new BN(2));
    const A2 = new BN(totalSupply).sub(new BN(sellAmountInWei)).pow(new BN(2));
    const A = A1.sub(A2);
    const B = new BN(3).mul(A).div(new BN(2));
    const C = B.div(new BN(10).pow(new BN(20)));
    return C;
  };

  const handleExecuteTransaction = async () => {
    // check if your balance is enough
    try {
      console.log('handleExecution');

      if (transactionTypeIsBUY) {
        if (
          parseFloat(Web3.utils.fromWei(accountSEEDBalance, 'ether')) <
          parseFloat(Web3.utils.fromWei(buyPriceInSEED, 'ether'))
        ) {
          showToast('insufficient funds', 'error');
          return;
        }
      }

      console.log('2');

      const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');

      const seedToken = new web3.eth.Contract(
        SEED_TOKEN_CONTRACT_ABI as AbiItem[],
        SEED_TOKEN_CONTRACT
      );
      const accounts = await web3.eth.requestAccounts();

      if (transactionTypeIsBUY) {
        const tokenTransaction = await seedToken.methods
          .buyStudentToken(
            talentToken?.symbol,
            Web3.utils.toWei(transactionAmount.toString(), 'ether')
          )
          .send({ from: accounts[0] });

        const { returnValues } =
          tokenTransaction.events.StudentTokenTransaction;

        const {
          walletAddress,
          amountInSeedTokens,
          amountInStudentTokens,
          symbol,
        } = returnValues;
        console.log('transaction', tokenTransaction);
        console.log(
          walletAddress,
          amountInSeedTokens,
          amountInStudentTokens,
          symbol
        );

        const backendURL: string = createURL(`portfolios/`);
        const res = await axios.put(
          backendURL,
          {
            ...editPortfolio,
            userID: 'new',
            tokens: editPortfolio.tokens.some((t) => t.userID === userID)
              ? editPortfolio.tokens
              : [
                  ...editPortfolio.tokens,
                  {
                    userID: userID,
                    contractAddress: talentToken?.contractAddress,
                    symbol: symbol,
                  },
                ],
          },
          createAuthHeader(await getAccessToken())
        );

        if (res.status === 200) {
          console.log('Successfully updated portfolio.');
        }
      } else {
        const tokenTransaction = await seedToken.methods
          .sellStudentToken(
            talentToken?.symbol,
            Web3.utils.toWei(transactionAmount.toString(), 'ether')
          )
          .send({ from: accounts[0] });
        console.log(tokenTransaction);
      }

      showToast('Transaction successfull');
    } catch (error) {
      console.log(error);
    }
  };

  const connectWallet = async () => {
    try {
      const connector = metamaskProvider;
      await activate(connector);
    } catch (error) {
      console.log(error);
    }
  };
  const disconnectWallet = async () => {
    try {
      await deactivate();
    } catch (error) {
      console.log(error);
    }
  };

  const deviceUserIsFollowingProfile = () => {
    return followerIDs?.some((id) => id === deviceUserID);
  };

  if (userProfileError) console.log('Failed to load user profile.');
  if (followerIDsError) console.log('Failed to load follower ids.');
  if (followingIDsError) console.log('Failed to load following ids.');

  return (
    <div>
      <div className='flex w-full items-center justify-center py-1'>
        {active ? (
          <PrimaryButton title='Disconnect Wallet' onClick={disconnectWallet} />
        ) : (
          <PrimaryButton title='Connect Wallet' onClick={connectWallet} />
        )}
      </div>

      <div className='p-1'>
        <div>
          <img
            className='h-48 w-full rounded object-cover '
            src={'../assets/images/earth.jpg'}
            alt='profile-header'
          />
        </div>

        <div className='flex py-2'>
          <div className='relative -top-16 mr-4 ml-4 -mb-16 flex-shrink-0'>
            <img
              className='h-32 w-32 rounded-full border-4 border-white object-cover'
              src={
                editUserProfile.profileImageURL
                  ? createS3ImageURL(editUserProfile.profileImageURL)
                  : '../assets/images/earth.jpg'
              }
              alt=''
            />
          </div>
          <div className='overflow-hidden pr-10'>
            <p
              className=' truncate font-mono text-sm'
              onClick={() => console.log(talentToken)}
            >
              {talentToken?.studentWalletAddress
                ? 'Wallet: ' + talentToken?.studentWalletAddress
                : '#walletAddress'}
            </p>
            {userID === deviceUserID && (
              <PrimaryButton
                title='Edit'
                onClick={() => setShowEditUserProfileModal(true)}
              />
            )}
          </div>
        </div>

        <div>
          <p className='text-xl font-bold'>
            {editUserProfile.firstName} {editUserProfile.lastName}{' '}
            {talentToken && `($${talentToken.symbol})`}
          </p>

          <div className='flex items-center justify-end gap-6 pr-10'>
            <div>
              <p className='font-bold'>12</p>
              <p>Supporters</p>
            </div>
            <div>
              <p className='font-bold'>1500</p>
              <p>Marketcap</p>
            </div>
          </div>

          <div>
            <p>{editUserProfile.bio}</p>
          </div>

          {userID === deviceUserID && (
            <PrimaryButton
              title='Edit wallet address'
              onClick={() => setShowEditWalletAddressModal(true)}
            />
          )}

          {/* <div className='py-2'>
              <p className='mr-2 inline rounded-full bg-orange-500 px-2 py-1 text-sm font-bold uppercase text-white'>
                Student
              </p>
              <p className='mr-2 inline rounded-full bg-orange-500 px-2 py-1 text-sm font-bold uppercase text-white'>
                Entrepreneur
              </p>
            </div> */}
        </div>

        <div className='scroll-hide no-scrollbar my-2 flex items-center overflow-x-scroll border-t-2 border-gray-600 bg-white'>
          <div
            className={`'cursor-pointer px-2 py-2 ${
              currentTab === 'info' ? 'border-b-2 border-red-500' : ''
            }`}
            onClick={() => setCurrentTab('info')}
          >
            <p className='font-bold'>Info</p>
          </div>
          <div
            className={`'cursor-pointer px-2 py-2 ${
              currentTab === 'feed' ? 'border-b-2 border-red-500' : ''
            }`}
            onClick={() => setCurrentTab('feed')}
          >
            <p className='font-bold'>Feed</p>
          </div>
          <div
            className={`'cursor-pointer px-2 py-2 ${
              currentTab === 'milestones' ? 'border-b-2 border-red-500' : ''
            }`}
            onClick={() => setCurrentTab('milestones')}
          >
            <p className='font-bold'>Milestones</p>
          </div>
          <div
            className={`'cursor-pointer px-2 py-2 ${
              currentTab === 'coin' ? 'border-b-2 border-red-500' : ''
            }`}
            onClick={() => setCurrentTab('coin')}
          >
            <p className='font-bold'>Coin</p>
          </div>
        </div>

        {/* <div className='flex w-full justify-center py-4'>
          <PrimaryButton
            className='w-full'
            title='Follow'
            onClick={() => console.log('follow clicked')}
          />
        </div> */}

        {currentTab === 'info' && (
          <>
            <div>
              <p className='font-bold'>About Me</p>
              <p>{editUserProfile.about}</p>
            </div>

            <div className='flex flex-col gap-2'>
              <p className='font-bold'>Perks</p>

              {editUserProfile.perks.map((perk) => (
                <div className='rounded bg-gray-300 p-2'>
                  <p className='font-bold'>{perk.name}</p>
                  <p>{perk.description}</p>
                  <p className='font-semibold'>
                    Holding amount: {perk.holdingAmount}
                  </p>
                  {userID === deviceUserID && (
                    <button
                      onClick={() => handleEditPerkClick(perk.position)}
                      className='m-1 rounded bg-gray-500 px-2 py-1 text-white'
                    >
                      Edit
                    </button>
                  )}
                </div>
              ))}
              {userID === deviceUserID && (
                <PrimaryButton
                  title='Add New Perk'
                  onClick={() => handleAddNewPerk()}
                />
              )}
            </div>
          </>
        )}

        {currentTab === 'feed' && (
          <>
            <div>
              <p>Feed coming soon...</p>
            </div>
          </>
        )}
        {currentTab === 'milestones' && (
          <>
            <div className='flex flex-col gap-2'>
              <p className='font-bold'>Milestones</p>

              {editUserProfile.milestones.map((milestone) => (
                <div className='mb-2 flex rounded-sm bg-white p-2 '>
                  <FontAwesomeIcon
                    className={`mr-2 mt-1 text-2xl  ${
                      milestone.completed ? 'text-green-700' : 'text-gray-500'
                    }`}
                    icon={
                      milestone.completed ? faCheckCircle : faQuestionCircle
                    }
                  />
                  <div>
                    <p className='font-bold'>{milestone.name}</p>
                    <p>{milestone.description}</p>
                    {/* <p className='text-sm text-gray-500'>Completed 12.04.2022</p> */}
                    {userID === deviceUserID && (
                      <button
                        onClick={() =>
                          handleEditMilestoneClick(milestone.position)
                        }
                        className={`m-1 rounded bg-gray-500 px-2 py-1 text-white`}
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {userID === deviceUserID && (
                <PrimaryButton
                  title='Add New Milestone'
                  onClick={() => handleAddNewMilestone()}
                />
              )}
            </div>
          </>
        )}
        {currentTab === 'coin' && (
          <>
            <div>
              <p>Coin info coming soon...</p>
            </div>
          </>
        )}

        {talentToken ? (
          <div className='flex w-full flex-col justify-center gap-2 py-4'>
            <PrimaryButton
              className='w-full'
              title={`Buy $${talentToken?.symbol}`}
              onClick={() => {
                setShowTransactionModal(true);
                setTransactionTypeIsBUY(true);
              }}
            />
            <PrimaryButton
              className='w-full'
              title={`Sell $${talentToken?.symbol}`}
              onClick={() => {
                setShowTransactionModal(true);
                setTransactionTypeIsBUY(false);
              }}
            />
          </div>
        ) : (
          <div className='py-4'>
            <p>No token was released for this profile yet.</p>
          </div>
        )}
      </div>

      {/* {userProfile && userProfile?.profileImageURL !== '' && (
        <img
          src={createS3ImageURL(userProfile.profileImageURL)}
          alt='log512.png'
        />
      )} */}

      {/* {deviceUserID !== userID && (
        <button className='orange-button' onClick={() => toggleFollowUser()}>
          {deviceUserIsFollowingProfile() ? 'Unfollow' : 'Follow'}
        </button>
      )} */}

      {/* <div>
        <p>Followers</p>
        {followerIDs?.map((id) => (
          <p onClick={() => navigate(`/profiles/${id}`)}>{id}</p>
        ))}
      </div>
      <div>
        <p>Following</p>
        {followingIDs?.map((id) => (
          <p onClick={() => navigate(`/profiles/${id}`)}>{id}</p>
        ))}
      </div> */}

      <Modal
        show={showEditUserProfileModal}
        handleClose={() => setShowEditUserProfileModal(false)}
      >
        {/* {editUserProfile.profileImageURL !== '' && (
          <img
            src={createS3ImageURL(editUserProfile.profileImageURL)}
            alt='log512.png'
          />
        )} */}

        <form
          className='max-h-96 overflow-y-scroll p-1'
          onSubmit={(event) => event.preventDefault()}
        >
          <div className='mb-2 flex items-center'>
            <label className='block w-48 font-bold text-gray-800'>
              First Name
            </label>
            <input
              className={`w-full appearance-none rounded border-2 bg-gray-200 py-2 px-4 leading-tight text-gray-800 focus:border-gray-500 focus:bg-gray-100 focus:outline-none`}
              name={'firstName'}
              placeholder={'e.g. John'}
              value={editUserProfile.firstName}
              onChange={handleOnChange}
              autoComplete='off'
              type='text'
            />
          </div>
          <div className='mb-2 flex items-center'>
            <label className='block w-48 font-bold text-gray-800'>
              Last Name
            </label>
            <input
              className={`w-full appearance-none rounded border-2 bg-gray-200 py-2 px-4 leading-tight text-gray-800 focus:border-gray-500 focus:bg-gray-100 focus:outline-none`}
              name={'lastName'}
              placeholder={'e.g. Smith'}
              value={editUserProfile.lastName}
              onChange={handleOnChange}
              autoComplete='off'
              type='text'
            />
          </div>

          <div className='mb-4'>
            <label className='mb-1 block text-sm font-bold text-gray-800'>
              Bio:
            </label>
            <textarea
              className={`form-control blsock m-0 w-full rounded border border-solid border-gray-300 bg-gray-200 bg-clip-padding px-3 py-1.5 text-base font-normal  text-gray-700 transition ease-in-out focus:border-2 focus:border-gray-600  focus:bg-gray-100 focus:text-gray-700 focus:outline-none `}
              name={'bio'}
              placeholder={'e.g. ambitious student'}
              value={editUserProfile.bio}
              onChange={handleChangeTextArea}
              autoComplete='off'
              rows={2}
            />
          </div>
          <div className='mb-4'>
            <label className='mb-1 block text-sm font-bold text-gray-800'>
              About me:
            </label>

            <textarea
              className={`form-control blsock m-0 w-full rounded border border-solid border-gray-300 bg-gray-200 bg-clip-padding px-3 py-1.5 text-base font-normal  text-gray-700 transition ease-in-out focus:border-2 focus:border-gray-600  focus:bg-gray-100 focus:text-gray-700 focus:outline-none `}
              name={'about'}
              placeholder={'e.g. ambitious student'}
              value={editUserProfile.about}
              onChange={handleChangeTextArea}
              autoComplete='off'
              rows={2}
            />
          </div>
          <img
            className='h-20 w-20 overflow-hidden rounded-full object-cover'
            src={createS3ImageURL(editUserProfile.profileImageURL)}
            alt='log512.png'
          />

          <div>
            <input type='file' name='profileImage' onChange={handleOnChange} />
            <button className='primary-button' onClick={uploadProfileImage}>
              Save profile image
            </button>
          </div>

          <div></div>
        </form>
        <button className='orange-button' onClick={handleSaveUserProfile}>
          Save
        </button>
      </Modal>

      <Modal
        show={showEditPerkModal}
        handleClose={() => setShowEditPerkModal(false)}
      >
        {/* {editUserProfile.profileImageURL !== '' && (
          <img
            src={createS3ImageURL(editUserProfile.profileImageURL)}
            alt='log512.png'
          />
        )} */}

        <form
          className='max-h-96 overflow-y-scroll p-1'
          onSubmit={(event) => event.preventDefault()}
        >
          <div className='mb-2 flex items-center'>
            <label className='block w-48 font-bold text-gray-800'>Title</label>
            <input
              className={`w-full appearance-none rounded border-2 bg-gray-200 py-2 px-4 leading-tight text-gray-800 focus:border-gray-500 focus:bg-gray-100 focus:outline-none`}
              name={'perk-name'}
              placeholder={'e.g. 30 minutes virtual coffe chat'}
              value={editUserProfile.perks[selectedPerkID]?.name}
              onChange={handleOnChange}
              autoComplete='off'
              type='text'
            />
          </div>
          <div className='mb-4'>
            <label className='mb-1 block text-sm font-bold text-gray-800'>
              Description:
            </label>
            <textarea
              className={`form-control blsock m-0 w-full rounded border border-solid border-gray-300 bg-gray-200 bg-clip-padding px-3 py-1.5 text-base font-normal  text-gray-700 transition ease-in-out focus:border-2 focus:border-gray-600  focus:bg-gray-100 focus:text-gray-700 focus:outline-none `}
              name={'perk-description'}
              placeholder={'e.g. 30 minutes virtual coffe chat'}
              value={editUserProfile.perks[selectedPerkID]?.description}
              onChange={handleChangeTextArea}
              autoComplete='off'
              rows={2}
            />
          </div>
          <div className='mb-2 flex items-center'>
            <label className='block w-48 font-bold text-gray-800'>
              Token holder amount
            </label>
            <input
              className={`w-full appearance-none rounded border-2 bg-gray-200 py-2 px-4 leading-tight text-gray-800 focus:border-gray-500 focus:bg-gray-100 focus:outline-none`}
              name={'perk-holdingAmount'}
              placeholder={'e.g. Smith'}
              value={editUserProfile.perks[selectedPerkID]?.holdingAmount}
              onChange={handleOnChange}
              autoComplete='off'
              type='text'
            />
          </div>
        </form>
        <button className='orange-button' onClick={savePerk}>
          Save Perk
        </button>
        <button className='red-button' onClick={deletePerk}>
          Delete Perk
        </button>
      </Modal>

      <Modal
        show={showEditMilestoneModal}
        handleClose={() => setShowEditMilestoneModal(false)}
      >
        <form
          className='max-h-96 overflow-y-scroll p-1'
          onSubmit={(event) => event.preventDefault()}
        >
          <div className='mb-2 flex items-center'>
            <label className='block w-48 font-bold text-gray-800'>Title</label>
            <input
              className={`w-full appearance-none rounded border-2 bg-gray-200 py-2 px-4 leading-tight text-gray-800 focus:border-gray-500 focus:bg-gray-100 focus:outline-none`}
              name={'milestone-name'}
              placeholder={'e.g. pass exam'}
              value={editUserProfile.milestones[selectedPerkID]?.name}
              onChange={handleOnChange}
              autoComplete='off'
              type='text'
            />
          </div>
          <div className='mb-4'>
            <label className='mb-1 block text-sm font-bold text-gray-800'>
              Description:
            </label>
            <textarea
              className={`form-control blsock m-0 w-full rounded border border-solid border-gray-300 bg-gray-200 bg-clip-padding px-3 py-1.5 text-base font-normal  text-gray-700 transition ease-in-out focus:border-2 focus:border-gray-600  focus:bg-gray-100 focus:text-gray-700 focus:outline-none `}
              name={'milestone-description'}
              placeholder={'a short description of your milestone'}
              value={editUserProfile.milestones[selectedPerkID]?.description}
              onChange={handleChangeTextArea}
              autoComplete='off'
              rows={2}
            />
          </div>
          <div className='mb-2 flex w-full items-center justify-center'>
            <PrimaryCheckbox
              name='completed'
              label='Milestone completed'
              value={editUserProfile.milestones[selectedMilestoneID]?.completed}
              onChange={() => toggleMilestoneCompletionStatus()}
            />
          </div>
        </form>
        <button className='orange-button' onClick={saveMilestone}>
          Save Milestone
        </button>
        <button className='red-button' onClick={deleteMilestone}>
          Delete Milestone
        </button>
      </Modal>

      <Modal
        show={showTransactionModal}
        handleClose={() => setShowTransactionModal(false)}
      >
        <p className='text-2xl font-semibold'>New Transaction:</p>
        <p className='py-2'>
          Current ${talentToken?.symbol} balance:{' '}
          <span className='font-semibold'>
            {Web3.utils.fromWei(accountTalentTokenBalance, 'ether')}
          </span>
        </p>
        <div className='flex w-full  items-center justify-center py-2'>
          <div
            className='justify-centerp-3 flex cursor-pointer items-center gap-2'
            onClick={() => setTransactionTypeIsBUY((state) => !state)}
          >
            <p
              className={`w-24 rounded py-2 text-center font-bold ${
                transactionTypeIsBUY
                  ? 'bg-primary-700 text-white'
                  : 'bg-gray-200 text-gray-400'
              }`}
            >
              BUY
            </p>
            <p
              className={`w-24 rounded py-2 text-center font-bold ${
                !transactionTypeIsBUY
                  ? 'bg-primary-700 text-white'
                  : 'bg-gray-200 text-gray-400'
              }`}
            >
              SELL
            </p>
          </div>
        </div>
        <form
          className='max-h-96 overflow-y-scroll p-1'
          onSubmit={(event) => event.preventDefault()}
        >
          <div className='mb-2 flex items-center'>
            <label className='block w-48 font-bold text-gray-800'>
              ${talentToken?.symbol} to {transactionTypeIsBUY ? 'BUY' : 'SELL'}:
            </label>
            <input
              className={`w-full appearance-none rounded border-2 bg-gray-200 py-2 px-4 leading-tight text-gray-800 focus:border-gray-500 focus:bg-gray-100 focus:outline-none`}
              name={'transactionAmount'}
              placeholder={'150'}
              value={transactionAmount}
              onChange={handleOnChange}
              autoComplete='off'
              type='number'
            />
          </div>
        </form>

        <div className='p-2'>
          <p>
            Price in SEED:{' '}
            <span className='font-semibold'>
              {Web3.utils.fromWei(
                transactionTypeIsBUY ? buyPriceInSEED : sellPriceInSEED,
                'ether'
              )}
            </span>{' '}
          </p>
          <p>
            Your SEED balance:{' '}
            <span className='font-semibold'>
              {Web3.utils.fromWei(accountSEEDBalance, 'ether')}
            </span>{' '}
            SEED
          </p>
        </div>

        <button className='orange-button' onClick={handleExecuteTransaction}>
          Confirm transaction
        </button>
      </Modal>

      <Modal
        show={showEditWalletAddressModal}
        handleClose={() => setShowEditWalletAddressModal(false)}
      >
        <form
          className='max-h-96 overflow-y-scroll p-1'
          onSubmit={(event) => event.preventDefault()}
        >
          <div className='mb-2 flex items-center'>
            <label className='block w-48 font-bold text-gray-800'>
              Your wallet address
            </label>
            <input
              className={`w-full appearance-none rounded border-2 bg-gray-200 py-2 px-4 leading-tight text-gray-800 focus:border-gray-500 focus:bg-gray-100 focus:outline-none`}
              name={'milestone-name'}
              placeholder={'e.g. pass exam'}
              value={editWalletAddress}
              onChange={(e) => setEditWalletAddress(e.target.value)}
              autoComplete='off'
              type='text'
            />
          </div>
        </form>
        <button className='orange-button' onClick={saveWalletAddress}>
          Save wallet address
        </button>
      </Modal>
    </div>
  );
};

export default UserProfile;
