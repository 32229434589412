import { useContext, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import userPool from '../userPool';
import { AuthContext } from '../provider/AuthProvider';
import { showToast } from '../helper';
import PrimaryInput from './inputs/PrimaryInput';
import PillButton from './buttons/PillButton';

const Login = () => {
  const navigate = useNavigate();
  const { isLoggedIn, usernamePasswordSignIn, logout, googleSignIn } =
    useContext(AuthContext);

  const [newUserSignUpSelected, setNewUserSignUpSelected] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');

  const signUpUser = async () => {
    if (password !== confirmPassword) {
      showToast('Password are not identical.', 'error');
      return;
    }

    try {
      userPool.signUp(username, password, [], [], (err, result) => {
        if (err) {
          console.log('err', err);
          throw new Error(err.message);
        }
        console.log('Signup complete!', result);
        navigate(`/verifyEmail?username=${username}`);
        setNewUserSignUpSelected(false);
        showToast('Successful!', 'success');
      });
    } catch (error) {
      showToast('Failed to signup new user.', 'error');
      console.log('Signup error', error);
    }
  };

  if (isLoggedIn()) {
    //TODO: Fetch url from localstorage, otherwise just send them to the / route
    return <Navigate to='/' replace />;
  }

  return (
    <div className='flex h-screen flex-col items-center bg-primary-700'>
      <div className='my-10 w-48'>
        <img src='assets/images/perseedu-logo.png' alt='perseedu-logo' />
      </div>
      <div className='flex items-center justify-center'>
        <PillButton onClick={googleSignIn}>
          Sign In with Google
          <img
            src='assets/images/google-icon.png'
            alt=''
            style={{ marginLeft: '6px' }}
          />
        </PillButton>
      </div>
      <div className='my-5 flex items-center justify-center gap-2'>
        <div className='h-px w-32 bg-white'></div>
        <p className='caption-10'>OR</p>
        <div className='h-px w-32 bg-white'></div>
      </div>
      <div className='w-80'>
        {newUserSignUpSelected ? (
          <div>
            <form
              className='short-form'
              onSubmit={(event) => event.preventDefault()}
            >
              <PrimaryInput
                value={username}
                name='username'
                onChange={(event) => setUsername(event.target.value)}
                placeholder='Email'
              />
              <PrimaryInput
                value={password}
                name='new-password'
                onChange={(event) => setPassword(event.target.value)}
                placeholder='Password'
                type='password'
              />
              <PrimaryInput
                value={confirmPassword}
                name='confirm-password'
                onChange={(event) => setConfirmPassword(event.target.value)}
                placeholder='Confirm Password'
                type='password'
              />
            </form>

            <div className='mt-3 flex items-center justify-center gap-2'>
              <PillButton
                className='!bg-primary-700 !text-white hover:!bg-primary-300'
                onClick={() => setNewUserSignUpSelected(false)}
              >
                Login
              </PillButton>

              <PillButton onClick={() => signUpUser()}>
                Create Account
              </PillButton>
            </div>
          </div>
        ) : (
          <>
            <div>
              <form
                className='short-form'
                onSubmit={(event) => event.preventDefault()}
              >
                <PrimaryInput
                  value={username}
                  name='username'
                  onChange={(event) => setUsername(event.target.value)}
                  placeholder='Email'
                />
                <PrimaryInput
                  value={password}
                  name='current-password'
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder='Password'
                  type='password'
                />
              </form>
              <div className='flex justify-end pb-2'>
                <p className='caption-10'>FORGOT PASSWORD</p>
              </div>
              <div className='mt-3 flex items-center justify-center gap-2'>
                <PillButton
                  onClick={() => usernamePasswordSignIn(username, password)}
                >
                  Login
                </PillButton>

                <PillButton
                  className='!bg-primary-700 !text-white hover:!bg-primary-300'
                  onClick={() => setNewUserSignUpSelected(true)}
                >
                  Create Account
                </PillButton>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
