import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createAuthHeader,
  createS3ImageURL,
  createURL,
  fetcherAuth,
  showToast,
} from '../helper';
import { AuthContext } from '../provider/AuthProvider';
import useSWR from 'swr';
import { IUser } from '../models/user';
import { IUserProfile } from '../models/userProfile';
import Feed from './Feed';
import { useWeb3React } from '@web3-react/core';
import { metamaskProvider } from '../connectors/metaMask';
import PrimaryButton from './buttons/PrimaryButton';
import Web3 from 'web3';
import erc20ABI from '../contracts/erc20ABI';
import {
  SEED_TOKEN_CONTRACT,
  SEED_TOKEN_CONTRACT_ABI,
} from '../contracts/SeedTokenABI';
import { AbiItem } from 'web3-utils';
import {
  STUDENT_TOKEN_FACTORY_CONTRACT,
  STUDENT_TOKEN_FACTORY_CONTRACT_ABI,
} from '../contracts/StudentTokenFactory';
import Modal from './Modal';
import { ITalentToken } from '../models/talentToken';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { logout, getAccessToken, isLoggedIn } = useContext(AuthContext);

  const { activate, active, deactivate, account, connector, library, chainId } =
    useWeb3React();

  const [ethAccount, setEthAccount] = useState('');
  const [accountBalance, setAccountBalance] = useState('0');
  const [accountSEEDBalance, setAccountSEEDBalance] = useState('0');

  const [adminWalletAddress, setAdminWalletAddress] = useState('');

  const [showCreateTokenModal, setShowCreateTokenModal] = useState(false);
  const [selectedUserID, setSelectedUserID] = useState('');
  const [showDeleteTokenModal, setShowDeleteTokenModal] = useState(false);

  const [newTokenName, setNewTokenName] = useState('');
  const [newTokenSymbol, setNewTokenSymbol] = useState('');

  const userURL: string = createURL(`users/`);
  const { data: user, error: userError } = useSWR<IUser>(userURL, async (url) =>
    fetcherAuth(url, await getAccessToken())
  );

  const userProfilesURL: string = createURL(`userProfiles`);
  const { data: userProfiles, error: userProfilesError } = useSWR<
    IUserProfile[]
  >(userProfilesURL, async (url) => fetcherAuth(url, await getAccessToken()));

  const usersURL: string = createURL(`users/all`);
  const { data: users, error: usersError } = useSWR<IUser[]>(
    usersURL,
    async (url) => fetcherAuth(url, await getAccessToken())
  );
  const talentTokensURL: string = createURL(`talentTokens/all`);
  const { data: talentTokens, error: talentTokensError } = useSWR<
    ITalentToken[]
  >(talentTokensURL, async (url) => fetcherAuth(url, await getAccessToken()));

  useEffect(() => {
    async function load() {
      const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
      const accounts = await web3.eth.requestAccounts();

      setEthAccount(accounts[0]);

      const balance = await web3.eth.getBalance(accounts[0]);
      setAccountBalance(balance);

      const SEEDAddress = SEED_TOKEN_CONTRACT;
      const tokenInst = new web3.eth.Contract(
        SEED_TOKEN_CONTRACT_ABI as AbiItem[],
        SEED_TOKEN_CONTRACT
      );
      const SEEDbalance = await tokenInst.methods.balanceOf(accounts[0]).call();
      setAccountSEEDBalance(SEEDbalance);

      const adminWallet = await tokenInst.methods.adminAddress().call();
      setAdminWalletAddress(adminWallet);
    }

    load();
  }, []);

  useEffect(() => {
    if (ethAccount) {
      const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');

      const studentTokenFactory = new web3.eth.Contract(
        STUDENT_TOKEN_FACTORY_CONTRACT_ABI as AbiItem[],
        STUDENT_TOKEN_FACTORY_CONTRACT
      );
      studentTokenFactory.events.StudentTokenCreated((event: any) => {
        console.log('eventListener:', event);
      });
      // const seedToken = new web3.eth.Contract(SEED_TOKEN_CONTRACT_ABI as AbiItem[], SEED_TOKEN_CONTRACT);
      // seedToken.events.MyEvent('StudentTokenTransaction', (event:any)=> {
      //     console.log(event);
      // })
    }
  }, [ethAccount]);

  const handleCreateTalentToken = async () => {
    try {
      if (!selectedUserID) return;

      const studentWallet = getUsersWalletAddress(selectedUserID);
      if (!studentWallet) return;
      if (!newTokenSymbol || !newTokenName) return;

      console.log(studentWallet, selectedUserID, newTokenName, newTokenSymbol);

      const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
      await web3.eth.requestAccounts();

      const studentTokenFactory = new web3.eth.Contract(
        STUDENT_TOKEN_FACTORY_CONTRACT_ABI as AbiItem[],
        STUDENT_TOKEN_FACTORY_CONTRACT
      );
      const creationReceipt = await studentTokenFactory.methods
        .createStudentToken(newTokenName, newTokenSymbol, studentWallet)
        .send({ from: ethAccount });
      console.log('creation: ', creationReceipt);

      const { returnValues } = creationReceipt.events.StudentTokenCreated;
      const { contractAddress, studentWalletAddress, symbol } = returnValues;
      console.log('contractAddress', contractAddress);
      console.log('studentWalletAddress', studentWalletAddress);
      console.log('symbol', symbol);

      // const userID = 'b70d8939-43b5-45af-a3b2-3ff412b767d3';
      const backendURL: string = createURL(`talentTokens`);
      const res = await axios.post(
        backendURL,
        {
          userID: selectedUserID,
          symbol: symbol,
          contractAddress: contractAddress,
          studentWalletAddress: studentWallet,
          tokenCreated: 0,
        },
        createAuthHeader(await getAccessToken())
      );

      if (res.status === 200) {
        console.log('Successfully created talentToken.');
        showToast('Successfully created TalentToken.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteStudentToken = async () => {
    try {
      if (!selectedUserID) return;

      const studentTokenContract = getTalentTokenContract(selectedUserID);
      const symbol = getTalentTokenSymbol(selectedUserID);

      if (!symbol || !studentTokenContract) return;

      const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');

      await web3.eth.requestAccounts();

      const studentTokenFactory = new web3.eth.Contract(
        STUDENT_TOKEN_FACTORY_CONTRACT_ABI as AbiItem[],
        STUDENT_TOKEN_FACTORY_CONTRACT
      );
      const creationReceipt = await studentTokenFactory.methods
        .deleteStudentToken(studentTokenContract, symbol)
        .send({ from: ethAccount });
      console.log('deletion: ', creationReceipt);

      const backendURL: string = createURL(`talentTokens/${selectedUserID}`);
      const res = await axios.delete(
        backendURL,

        createAuthHeader(await getAccessToken())
      );

      if (res.status === 200) {
        console.log('Successfully deleted talentToken.');
        showToast('Successfully deleted TalentToken.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchFactoryDetails = async () => {
  //   try {
  //     const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
  //     const studentTokenFactory = new web3.eth.Contract(
  //       STUDENT_TOKEN_FACTORY_CONTRACT_ABI as AbiItem[],
  //       STUDENT_TOKEN_FACTORY_CONTRACT
  //     );

  //     const adminAddress = await studentTokenFactory.methods
  //       .adminAddress()
  //       .call();
  //     console.log(adminAddress);

  //     const symbol = 'AAA';
  //     const studenttokenAddress = await studentTokenFactory.methods
  //       .symbolsToStudentTokenContract(symbol)
  //       .call();
  //     console.log(studenttokenAddress);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const connectWallet = async () => {
    try {
      const connector = metamaskProvider;
      await activate(connector);
    } catch (error) {
      console.log(error);
    }
  };
  const disconnectWallet = async () => {
    try {
      await deactivate();
    } catch (error) {
      console.log(error);
    }
  };

  const getTalentTokenSymbol = (userID: string) => {
    if (!talentTokens) return '';
    const res = talentTokens.find((t) => t.userID === userID)?.symbol;
    return res || '';
  };
  const getTalentTokenContract = (userID: string) => {
    if (!talentTokens) return '';
    const res = talentTokens.find((t) => t.userID === userID)?.contractAddress;
    return res || '';
  };

  const getUsersWalletAddress = (userID: string) => {
    if (!users) return '';
    const res = users.find((u) => u.userID === userID)?.walletAddress;
    return res || '';
  };

  if (userProfilesError) console.log('Failed to load user profiles.');

  return (
    <>
      <div className='bg-gray-100 p-2'>
        <p className='text-3xl font-bold'>Admin Dashboard</p>
        <p className='py-2 font-mono font-bold text-green-700'>
          You need to have the following admin wallet address to create/delete
          tokens: {adminWalletAddress}
        </p>

        <div
          className='my-2 bg-gray-300 p-2'
          onClick={() => console.log(connector?.getProvider())}
        >
          <p className='font-bold'>Your wallet:</p>
          <p>Connected: {active ? 'true' : 'false'}</p>
          <p>Chain ID: {chainId}</p>
          <p>Wallet address: {ethAccount}</p>
          <p>BNB Balance: {Web3.utils.fromWei(accountBalance, 'ether')}</p>
          <p>
            SEED_BETA Balance: {Web3.utils.fromWei(accountSEEDBalance, 'ether')}
          </p>
          {active ? (
            <PrimaryButton
              title='Disconnect Wallet'
              onClick={disconnectWallet}
            />
          ) : (
            <PrimaryButton title='Connect Wallet' onClick={connectWallet} />
          )}
        </div>

        <p className='py-2 text-lg font-bold'>All talents</p>
        <div className='my-1 flex flex-col gap-1'>
          {userProfiles?.map((user) => (
            <div className='bg-gray-200 p-1'>
              <p className='font-bold'>
                {user.firstName} {user.lastName}
              </p>
              <p className='font-mono text-sm'>UserID: {user.userID}</p>
              <p className='font-mono text-sm'>
                Wallet: {getUsersWalletAddress(user.userID)}
              </p>
              {getTalentTokenContract(user.userID) && (
                <p className='font-mono text-sm'>
                  Contract: {getTalentTokenContract(user.userID)}
                </p>
              )}
              {getTalentTokenSymbol(user.userID) && (
                <p className='font-mono text-sm font-bold'>
                  Symbol: {getTalentTokenSymbol(user.userID)}
                </p>
              )}
              <div>
                {getTalentTokenContract(user.userID) ? (
                  <PrimaryButton
                    className='!bg-red-500'
                    title='Delete Token'
                    onClick={() => {
                      setSelectedUserID(user.userID);
                      setShowDeleteTokenModal(true);
                    }}
                  />
                ) : (
                  <PrimaryButton
                    title='Launch Token'
                    onClick={() => {
                      setSelectedUserID(user.userID);
                      setShowCreateTokenModal(true);
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        show={showCreateTokenModal}
        handleClose={() => setShowCreateTokenModal(false)}
      >
        <p>Create new token:</p>
        <form
          className='max-h-96 overflow-y-scroll p-1'
          onSubmit={(event) => event.preventDefault()}
        >
          <div className='mb-2 flex items-center'>
            <label className='block w-48 font-bold text-gray-800'>
              Token Symbol
            </label>
            <input
              className={`w-full appearance-none rounded border-2 bg-gray-200 py-2 px-4 leading-tight text-gray-800 focus:border-gray-500 focus:bg-gray-100 focus:outline-none`}
              name={'firstName'}
              placeholder={'e.g. John Smith Token'}
              value={newTokenName}
              onChange={(e) => setNewTokenName(e.target.value)}
              autoComplete='off'
              type='text'
            />
          </div>
          <div className='mb-2 flex items-center'>
            <label className='block w-48 font-bold text-gray-800'>
              Token Name
            </label>
            <input
              className={`w-full appearance-none rounded border-2 bg-gray-200 py-2 px-4 leading-tight text-gray-800 focus:border-gray-500 focus:bg-gray-100 focus:outline-none`}
              name={'lastName'}
              placeholder={'e.g. ABC'}
              value={newTokenSymbol}
              onChange={(e) => setNewTokenSymbol(e.target.value)}
              autoComplete='off'
              type='text'
            />
          </div>
        </form>
        <button className='orange-button' onClick={handleCreateTalentToken}>
          Save
        </button>
      </Modal>
      <Modal
        show={showDeleteTokenModal}
        handleClose={() => setShowDeleteTokenModal(false)}
      >
        <p>Delete the token for user with userID: {selectedUserID}</p>

        <button className='orange-button' onClick={() => deleteStudentToken()}>
          Delete from blockchain
        </button>
        <button
          className='black-button'
          onClick={() => {
            setSelectedUserID('');
            setShowDeleteTokenModal(false);
          }}
        >
          Cancel
        </button>
      </Modal>
    </>
  );
};

export default AdminDashboard;
