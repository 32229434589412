import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createAuthHeader,
  createS3ImageURL,
  createURL,
  fetcherAuth,
} from '../helper';
import { AuthContext } from '../provider/AuthProvider';
import useSWR from 'swr';
import { IUser } from '../models/user';
import { IUserProfile } from '../models/userProfile';
import Feed from './Feed';
import { useWeb3React } from '@web3-react/core';
import { metamaskProvider } from '../connectors/metaMask';
import PrimaryButton from './buttons/PrimaryButton';
import Web3 from 'web3';
import erc20ABI from '../contracts/erc20ABI';
import { SEED_TOKEN_CONTRACT } from '../contracts/SeedTokenABI';
import { IPortfolio } from '../models/portfolio';
import { STUDENT_TOKEN_CONTRACT_ABI } from '../contracts/StudentToken';
import { AbiItem } from 'web3-utils';

const Portfolio = () => {
  const navigate = useNavigate();
  const { logout, getAccessToken, isLoggedIn } = useContext(AuthContext);

  const { activate, active, deactivate, account, connector, library, chainId } =
    useWeb3React();

  const [ethAccount, setEthAccount] = useState('');
  const [accountBalance, setAccountBalance] = useState('0');
  const [accountSEEDBalance, setAccountSEEDBalance] = useState('0');

  const userURL: string = createURL(`users/`);
  const { data: user, error: userError } = useSWR<IUser>(userURL, async (url) =>
    fetcherAuth(url, await getAccessToken())
  );

  const userProfilesURL: string = createURL(`userProfiles`);
  const { data: userProfiles, error: userProfilesError } = useSWR<
    IUserProfile[]
  >(userProfilesURL, async (url) => fetcherAuth(url, await getAccessToken()));

  const portfolioURL: string = createURL(`portfolios`);
  const { data: portfolio, error: portfolioError } = useSWR<IPortfolio>(
    portfolioURL,
    async (url) => fetcherAuth(url, await getAccessToken())
  );

  useEffect(() => {
    async function load() {
      const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
      const accounts = await web3.eth.requestAccounts();

      setEthAccount(accounts[0]);

      const balance = await web3.eth.getBalance(accounts[0]);
      setAccountBalance(balance);

      const SEEDAddress = SEED_TOKEN_CONTRACT;
      const tokenInst = new web3.eth.Contract(erc20ABI, SEEDAddress);
      const SEEDbalance = await tokenInst.methods.balanceOf(accounts[0]).call();
      setAccountSEEDBalance(SEEDbalance);
    }

    load();
  }, []);

  // useEffect(() => {
  //   console.log('useEffect dashboard');
  //   if (!isLoggedIn) {
  //     console.log('dashboard navigate to login');
  //     navigate('/login');
  //   }
  // }, [navigate, isLoggedIn]);

  //   useEffect(() => {
  //     if (userError?.response?.data === 'User was null') {
  //       navigate('/newUser');
  //     }
  //   }, [userError, navigate]);

  //   const deleteUser = async () => {
  //     const backendURL: string = createURL('users');
  //     try {
  //       const res = await axios.delete(
  //         backendURL,
  //         createAuthHeader(await getAccessToken())
  //       );

  //       if (res.status === 200) {
  //         console.log('Successfully deleted user.');
  //         logout();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const connectWallet = async () => {
    try {
      const connector = metamaskProvider;
      await activate(connector);
    } catch (error) {
      console.log(error);
    }
  };
  const disconnectWallet = async () => {
    try {
      await deactivate();
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchBalance = async () => {
  //   try {
  //     const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
  //     const balance = await web3.eth.getBalance(ethAccount);
  //     return await parseFloat(balance);
  //   } catch (error) {
  //     console.log(error);
  //     return 0;
  //   }
  // };

  const [tokenBalances, setTokenBalances] = useState<
    { userID: string; balance: string }[]
  >([]);
  useEffect(() => {
    const load = async () => {
      if (!portfolio) return;

      const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
      const accounts = await web3.eth.requestAccounts();

      for (const token of portfolio?.tokens) {
        const studentToken = new web3.eth.Contract(
          STUDENT_TOKEN_CONTRACT_ABI as AbiItem[],
          token.contractAddress
        );
        const studentTokenBalance = await studentToken.methods
          .balanceOf(accounts[0])
          .call();

        setTokenBalances((prevBalances) =>
          prevBalances.some((b) => b.userID === token.userID)
            ? prevBalances.map((b) =>
                b.userID === token.userID
                  ? { ...b, balance: studentTokenBalance }
                  : b
              )
            : [
                ...prevBalances,
                { userID: token.userID, balance: studentTokenBalance },
              ]
        );
      }

      setEthAccount(accounts[0]);

      const balance = await web3.eth.getBalance(accounts[0]);
      setAccountBalance(balance);

      const SEEDAddress = SEED_TOKEN_CONTRACT;
      const tokenInst = new web3.eth.Contract(erc20ABI, SEEDAddress);
      const SEEDbalance = await tokenInst.methods.balanceOf(accounts[0]).call();
      setAccountSEEDBalance(SEEDbalance);
    };
    load();
  });

  const getUserName = (userID: string) => {
    if (userProfiles?.length === 0) return '';
    const userProfile = userProfiles?.find((u) => u.userID === userID);
    if (!userProfile) return '';
    return `${userProfile?.firstName} ${userProfile?.lastName}`;
  };

  const getBalanceOfTalentToken = (userID: string) => {
    const balance = tokenBalances.find((t) => t.userID === userID)?.balance;
    return balance || '0';
  };

  if (userProfilesError) console.log('Failed to load user profiles.');

  return (
    <div className='bg-gray-100 p-2'>
      {active ? (
        <PrimaryButton title='Disconnect Wallet' onClick={disconnectWallet} />
      ) : (
        <PrimaryButton title='Connect Wallet' onClick={connectWallet} />
      )}

      <div
        className='my-3 overflow-hidden bg-gray-300 p-2'
        onClick={() => console.log(connector?.getProvider())}
      >
        <p
          className=' text-2xl font-bold'
          onDoubleClick={() => navigate('/admin')}
        >
          Your Wallet
        </p>
        <p>Connected: {active ? 'true' : 'false'}</p>
        <p className='truncate'>Wallet address: {ethAccount}</p>
        {/* <p>
          AVAX Balance:{' '}
          <span className='font-bold'>
            {Web3.utils.fromWei(accountBalance, 'ether')}
          </span>{' '}
        </p> */}
        <p>
          SEED token balance:{' '}
          <span className='font-bold'>
            {Web3.utils.fromWei(accountSEEDBalance, 'ether')}
          </span>{' '}
        </p>
      </div>

      <p
        className='my-1 font-bold'
        onClick={async () => console.log(`Bearer ${await getAccessToken()}`)}
      >
        Talent-Tokens
      </p>

      <div className='flex flex-col'>
        {portfolio?.tokens?.map((token) => (
          <div className='flex justify-between rounded bg-white p-2 py-3'>
            <div>
              {' '}
              <p className='font-bold'>
                {getUserName(token.userID)} ${token.symbol}
              </p>
              <p>
                Your balance:{' '}
                {Web3.utils.fromWei(
                  getBalanceOfTalentToken(token.userID),
                  'ether'
                )}
              </p>
            </div>
            <PrimaryButton
              title='Go to Profile'
              onClick={() => navigate(`/profiles/${token.userID}`)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
