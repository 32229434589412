import axios, { AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAuthHeader, createURL } from '../helper';
import { IUser } from '../models/user';
import { AuthContext } from '../provider/AuthProvider';
import PrimaryButton from './buttons/PrimaryButton';

const NewUser = () => {
  const navigate = useNavigate();
  const { getAccessToken } = useContext(AuthContext);
  const [editUser, setEditUser] = useState<IUser>({
    userID: 'new',
    firstName: '',
    lastName: '',
    mail: '',
    lastLogin: 0,
    userCreated: 0,
    walletAddress: '',
  });

  const createNewUser = async () => {
    if (editUser.firstName == null || editUser.lastName == null) {
      console.log('Please fill in all mandatory fields.');
      return;
    }

    const backendURL: string = createURL('users');
    try {
      const res: AxiosResponse = await axios.post(
        backendURL,
        {
          firstName: editUser.firstName,
          lastName: editUser.lastName,
        },
        createAuthHeader(await getAccessToken())
      );

      if (res.status === 200) {
        console.log('Successfully created new user.');
        navigate('/onboardUser');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='h-screen w-full bg-primary-700 p-2 pt-12'>
      <div className='absolute top-0 p-2'>
        <img className='w-32' src='../assets/images/perseedu-logo.png' alt='' />
      </div>
      <div className='flex h-full flex-col items-center rounded bg-white'>
        <div className='flex justify-center p-10'>
          <p className='text-xl font-bold '>Create profile</p>
        </div>
        <div className='w-full max-w-sm rounded-lg p-2'>
          <form
            className='flex flex-col'
            onSubmit={(event) => event.preventDefault()}
          >
            <label className='text-md pb-1'>First Name</label>
            <input
              className='mr-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1'
              value={editUser.firstName}
              placeholder='e.g. Jessica'
              onChange={(event) =>
                setEditUser((prevUser) => ({
                  ...prevUser,
                  firstName: event.target.value,
                }))
              }
            />
            <label className='text-md pt-3 pb-1'>Last Name</label>
            <input
              className='mr-2 rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1'
              value={editUser.lastName}
              placeholder='e.g. Smith'
              onChange={(event) =>
                setEditUser((prevUser) => ({
                  ...prevUser,
                  lastName: event.target.value,
                }))
              }
            />
          </form>
          <div className='mt-5 flex justify-end pr-2'>
            <PrimaryButton title='Save' onClick={() => createNewUser()} />
          </div>
        </div>
        <div className='mt-12'>
          <p className='text-sm font-bold'>Step 1/2</p>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
